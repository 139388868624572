import React from 'react';
import { useLocation } from 'react-router-dom';
import LiveGames from './LiveGames';
import ScheduledGames from './ScheduledGames';
import LastNightGames from './LastNightGames';
import SearchBar from '../../components/SearchBar';
import ArrowIcon from '../../assets/dashboard-icons/cirlcle-arrow-slash.svg';

const Games = () => {
  const location = useLocation();

  const renderPageTitle = () => {
    switch (true) {
      case location.pathname.includes('live'):
        return 'Live Games';
      case location.pathname.includes('scheduled'):
        return 'Scheduled Games';
      case location.pathname.includes('last-night'):
        return "Last Night's Games";
      default:
        return 'Live Games';
    }
  };

  const renderGamePage = () => {
    switch (true) {
      case location.pathname.includes('live'):
        return <LiveGames />;
      case location.pathname.includes('scheduled'):
        return <ScheduledGames />;
      case location.pathname.includes('last-night'):
        return <LastNightGames />;
      default:
        return <LiveGames />;
    }
  };

  return (
    <div className="gap-2 h-fit min-h-screen">
      <SearchBar pageTitle={renderPageTitle()} icon={ArrowIcon} searchValue={''} />

      {/* Top Section */}
      <div className="grid h-full lg:mx-8">
        {renderGamePage()}
      </div>
    </div>
  );
};

export default Games;
