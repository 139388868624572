import React, { useEffect, useState } from 'react';
import LastNightGameCard from '../../components/GameCard/LastNightGameCard';
import moment from 'moment-timezone';
import { usePosOddsAPI } from '../../hooks/usePosOddsAPI';
import { gameIsNotOngoing } from '../../utils';

const LastNightGames = () => {
  const [lastNightGames, setLastNightGames] = useState([]);

  const { getGamesByDate } = usePosOddsAPI();

  const handleGetGamesLastNight = async () => {
    const dateLastNight = moment()
      .tz('America/New_York')
      .subtract(1, 'days')
      .format('YYYY-MM-DD');

    let lastNightGames = [];
    try {
      const res = await getGamesByDate(dateLastNight);

      if (res.data.data.length > 0) {
        const finishedGames = res.data.data.filter((game) =>
          gameIsNotOngoing(game)
        );

        lastNightGames = [...lastNightGames, ...finishedGames];
      }

      const gamesSorted = lastNightGames.sort(
        (a, b) =>
          new Date(b.DateTime).getTime() - new Date(a.DateTime).getTime()
      );
      setLastNightGames(gamesSorted);
    } catch (error) {
      setLastNightGames([]);
    }
  };

  useEffect(() => {
    handleGetGamesLastNight();
  }, []);

  return (
    <div className="h-full w-full text-main-white">
      <div></div>
      <div className="flex w-full justify-center md:justify-start text-xl py-6">
        <div className=" text-main-gray-text">
          {moment(new Date())
            .tz('America/New_York')
            .subtract(1, 'days')
            .format('MMMM DD, dddd')}
        </div>
      </div>

      <div
        className={`grid grid-flow-row md:grid-flow-col auto-cols-max gap-5 pb-10 lg:px-2 px-4 lg:max-w-[75vw] max-w-[100vw] overflow-y-hidden snap-proximity snap-x 
                      ${
                        lastNightGames.length > 0
                          ? ' overflow-x-scroll'
                          : ' overflow-x-hidden'
                      }`}
      >
        {/* TODO: Update mapping on actual game date */}
        {lastNightGames.length > 0 ? (
          lastNightGames.map((game, id) => {
            return <LastNightGameCard key={id} game={game} />;
          })
        ) : (
          <div>No Games last night</div>
        )}
      </div>
    </div>
  );
};

export default LastNightGames;
