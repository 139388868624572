import React from 'react';

const OldPagination = ({ table }) => {
  return (
    <div className="flex justify-between">
      <span className="flex items-center gap-1 ml-4">
        <div>Page</div>
        <strong>
          {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
        </strong>
      </span>
      <div>
        <button
          className={`m-1 rounded border p-2 ${
            !table.getCanPreviousPage() && 'opacity-20'
          }`}
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}
        >
          {'<<'}
        </button>
        <button
          className={`m-1 rounded border p-2 ${
            !table.getCanPreviousPage() && 'opacity-20'
          }`}
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          {'<'}
        </button>
        <span>{table.getState().pagination.pageIndex + 1}</span>
        <button
          className={`m-1 rounded border p-2 ${
            !table.getCanNextPage() && 'opacity-20'
          }`}
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          {'>'}
        </button>
        <button
          className={`m-1 rounded border p-2 ${
            !table.getCanNextPage() && 'opacity-20'
          }`}
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
        >
          {'>>'}
        </button>
      </div>
    </div>
  );
};

export default OldPagination;
