import React, { useEffect, useState } from 'react';
import * as NBAIcons from 'react-nba-logos';
import BaseButton from '../Buttons/BaseButton';
import moment from 'moment-timezone';
import { parseTeamName } from '../../utils';
import { usePosOddsAPI } from '../../hooks/usePosOddsAPI';
import { TailSpin } from 'react-loader-spinner';

const UpcomingGameCard = ({
  game,
  setShowSignalModal,
  setSelectedGame,
  teamConfig,
}) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [gameDetails, setGameDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const HomeIcon = NBAIcons[parseTeamName(game.HomeTeam)];
  const AwayIcon = NBAIcons[parseTeamName(game.AwayTeam)];

  const { getGameSignal, getTeamConfig } = usePosOddsAPI();

  const getGameConfig = async () => {
    setIsLoading(true);
    const { GameID, AwayTeam, HomeTeam } = game;

    try {
      const gameSignal = await getGameSignal(GameID);

      if (
        gameSignal.data.awayMoneylineIndicator !== null ||
        gameSignal.data.homeMoneylineIndicator !== null
      ) {
        setIsLoading(false);
        return gameSignal.data;
      } else {
        const homeConfig = teamConfig[parseTeamName(HomeTeam)];
        const awayConfig = teamConfig[parseTeamName(AwayTeam)];
        setIsLoading(false);

        return {
          enableHomeMoneylineAlertSMS: homeConfig.enableHomeMoneylineAlertSMS,
          enableAwayMoneylineAlertSMS: awayConfig.enableHomeMoneylineAlertSMS,
          enableHomeTrendAlertSMS: homeConfig.enableHomeTrendAlertSMS,
          enableAwayTrendAlertSMS: awayConfig.enableAwayTrendAlertSMS,
          homeMoneylineIndicator: homeConfig.requiredMovementForMoneyLineSignal,
          awayMoneylineIndicator: awayConfig.requiredMovementForMoneyLineSignal,
          homeTrendingIndicator: homeConfig.requiredMovementForTrendingSignal,
          awayTrendinglineIndicator:
            awayConfig.requiredMovementForTrendingSignal,
        };
      }
    } catch (error) {
      try {
        const homeConfig = teamConfig[parseTeamName(HomeTeam)];
        const awayConfig = teamConfig[parseTeamName(AwayTeam)];
        setIsLoading(false);

        return {
          enableHomeMoneylineAlertSMS: homeConfig.enableHomeMoneylineAlertSMS,
          enableAwayMoneylineAlertSMS: awayConfig.enableHomeMoneylineAlertSMS,
          enableHomeTrendAlertSMS: homeConfig.enableHomeTrendAlertSMS,
          enableAwayTrendAlertSMS: awayConfig.enableAwayTrendAlertSMS,
          homeMoneylineIndicator: homeConfig.requiredMovementForMoneyLineSignal,
          awayMoneylineIndicator: awayConfig.requiredMovementForMoneyLineSignal,
          homeTrendingIndicator: homeConfig.requiredMovementForTrendingSignal,
          awayTrendinglineIndicator:
            awayConfig.requiredMovementForTrendingSignal,
        };
      } catch (error) {
        const homeConfig = teamConfig[HomeTeam];
        const awayConfig = teamConfig[AwayTeam];
        setIsLoading(false);

        return {
          enableHomeMoneylineAlertSMS: homeConfig.enableHomeMoneylineAlertSMS,
          enableAwayMoneylineAlertSMS: awayConfig.enableHomeMoneylineAlertSMS,
          enableHomeTrendAlertSMS: homeConfig.enableHomeTrendAlertSMS,
          enableAwayTrendAlertSMS: awayConfig.enableAwayTrendAlertSMS,
          homeMoneylineIndicator: homeConfig.requiredMovementForMoneyLineSignal,
          awayMoneylineIndicator: awayConfig.requiredMovementForMoneyLineSignal,
          homeTrendingIndicator: homeConfig.requiredMovementForTrendingSignal,
          awayTrendinglineIndicator:
            awayConfig.requiredMovementForTrendingSignal,
        };
      }
    }
  };

  const handleGetGameDetails = async (game) => {
    try {
      setGameDetails({
        ...game,
        // pregameOdds: preGameOdds.data.data.PregameOdds[0] || null,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetGameConfig = async (game) => {
    try {
      const gameSignal = await getGameConfig();

      setGameDetails({
        ...game,

        gameSignal,
      });
    } catch (error) {
      const homeConfig = teamConfig[game.HomeTeam];
      const awayConfig = teamConfig[game.AwayTeam];

      setGameDetails({
        ...game,
        gameSignal: {
          enableHomeMoneylineAlertSMS: homeConfig.enableHomeMoneylineAlertSMS,
          enableAwayMoneylineAlertSMS: awayConfig.enableHomeMoneylineAlertSMS,
          enableHomeTrendAlertSMS: homeConfig.enableHomeTrendAlertSMS,
          enableAwayTrendAlertSMS: awayConfig.enableAwayTrendAlertSMS,
          homeMoneylineIndicator: homeConfig.requiredMovementForMoneyLineSignal,
          awayMoneylineIndicator: awayConfig.requiredMovementForMoneyLineSignal,
          homeTrendingIndicator: homeConfig.requiredMovementForTrendingSignal,
          awayTrendinglineIndicator:
            awayConfig.requiredMovementForTrendingSignal,
        },
      });
    }
  };

  useEffect(() => {
    const role = localStorage.getItem('role');

    if (role === 'admin') {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, []);

  useEffect(() => {
    handleGetGameDetails(game);

    if (isAdmin) {
      handleGetGameConfig(game);
    }
  }, [game, isAdmin]);

  return (
    <div className=" bg-card-bg flex flex-col items-center p-8 rounded-xl text-main-white font-inter w-full snap-center">
      <div>
        <div></div>
        <div>
          <div className="flex items-center">
            <div>
              <AwayIcon />
            </div>
            <div className=" text-xl opacity-0">100 - 100</div>
            <div>
              <HomeIcon />
            </div>
          </div>
          <div className="flex items-center justify-center">
            <div className="flex flex-col items-center">
              <div className="font-semibold text-lg">
                {gameDetails.AwayTeam}
              </div>
              <div className=" font-light text-xs">Away</div>
            </div>
            <div className="bg-secondary-white text-main-dark-green px-1 py-1 rounded mx-10 text-center">
              {/* TODO: Replace with scheduled time of game */}
              {moment(gameDetails.DateTime).format('hh:mm A')}
            </div>
            <div className="flex flex-col items-center">
              <div className="font-semibold text-lg">
                {gameDetails.HomeTeam}
              </div>
              <div className=" font-light text-xs">Home</div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center mt-4 w-full">
        <div className="rounded-md flex flex-col justify-between p-4 w-full font-semibold text-base gap-3">
          <div className="flex justify-around w-full">
            {/* TODO: Create a utils parser for empty, null, and undefined values */}
            <div>
              {gameDetails.pregameOdds
                ? gameDetails.pregameOdds.AwayPointSpread * -1
                : gameDetails.PointSpread
                ? gameDetails.PointSpread * -1
                : 0}
            </div>
            <div className="font-light">Open spread</div>
            <div>
              {gameDetails.pregameOdds
                ? gameDetails.pregameOdds.HomePointSpread
                : gameDetails.PointSpread
                ? gameDetails.PointSpread
                : 0}
            </div>
          </div>
          <div className="flex justify-around mt-4">
            <div>
              {gameDetails.pregameOdds
                ? gameDetails.pregameOdds.AwayMoneyLine
                : gameDetails.AwayTeamMoneyLine
                ? gameDetails.AwayTeamMoneyLine
                : 0}
            </div>
            <div className="font-light">Money line</div>
            <div>
              {gameDetails.pregameOdds
                ? gameDetails.pregameOdds.HomeMoneyLine
                : gameDetails.HomeTeamMoneyLine
                ? gameDetails.HomeTeamMoneyLine
                : 0}
            </div>
          </div>
          {/* <div className="flex justify-around">
            <div>{game.away.current.liveline}</div>
            <div className="font-light">Live line</div>
            <div>{game.home.current.liveline}</div>
          </div> */}
        </div>
      </div>
      {isAdmin && isLoading ? (
        <div className="flex h-full flex-col justify-center items-center">
          <TailSpin
            height="120"
            width="120"
            color="#5AC561"
            ariaLabel="tail-spin-loading"
            radius="0"
            wrapperStyle={{}}
            wrapperClass=""
            visible={isLoading}
          />
        </div>
      ) : (
        isAdmin &&
        gameDetails.gameSignal && (
          <>
            <div className="flex flex-col items-center">
              <div className=" w-desktop-inner-w-live rounded-md flex flex-col justify-between p-4">
                <div className="flex justify-around">
                  <div>
                    {gameDetails.gameSignal
                      ? gameDetails.gameSignal.enableAwayMoneylineAlertSMS
                        ? 'Enabled'
                        : 'Disabled'
                      : 'Disabled'}
                  </div>
                  <div className="font-light">SMS Signal</div>
                  <div>
                    {gameDetails.gameSignal
                      ? gameDetails.gameSignal.enableHomeMoneylineAlertSMS
                        ? 'Enabled'
                        : 'Disabled'
                      : 'Disabled'}
                  </div>
                </div>
                <div className="flex justify-around mt-4">
                  <div>
                    {gameDetails.gameSignal
                      ? gameDetails.gameSignal.awayMoneylineIndicator
                      : '-'}
                  </div>
                  <div className="font-light">Movement</div>
                  <div>
                    {gameDetails.gameSignal
                      ? gameDetails.gameSignal.homeMoneylineIndicator
                      : '-'}
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-4 w-full">
              <BaseButton
                config={'PRIMARY'}
                btnName={'Set Game Signals'}
                onClickHandler={() => {
                  setSelectedGame({
                    gameId: gameDetails.GameID,
                    homeTeam: gameDetails.HomeTeam,
                    awayTeam: gameDetails.AwayTeam,
                    gameSignal: gameDetails.gameSignal,
                  });
                  setShowSignalModal(true);
                }}
              />
              <BaseButton
                btnName={'Disable Game Signal'}
                config={'SECONDARY'}
                onClickHandler={() => {
                  setSelectedGame({
                    gameId: gameDetails.GameID,
                    homeTeam: gameDetails.HomeTeam,
                    awayTeam: gameDetails.AwayTeam,
                    gameSignal: gameDetails.gameSignal,
                    disableSMS: true,
                  });
                  setShowSignalModal(true);
                }}
                disableSMS={
                  !gameDetails.gameSignal.enableAwayMoneylineAlertSMS &&
                  !gameDetails.gameSignal.enableHomeMoneylineAlertSMS
                }
              />
            </div>
          </>
        )
      )}

      {/* {isAdmin && gameDetails.gameSignal && (
        <div className="flex flex-col items-center">
          <div className=" w-desktop-inner-w-live rounded-md flex flex-col justify-between p-4">
            <div className="flex justify-around">
              <div>
                {gameDetails.gameSignal
                  ? gameDetails.gameSignal.enableAwaySMS
                    ? 'Enabled'
                    : 'Disabled'
                  : 'Disabled'}
              </div>
              <div className="font-light">SMS Signal</div>
              <div>
                {gameDetails.gameSignal
                  ? gameDetails.gameSignal.enableHomeSMS
                    ? 'Enabled'
                    : 'Disabled'
                  : 'Disabled'}
              </div>
            </div>
            <div className="flex justify-around mt-4">
              <div>
                {gameDetails.gameSignal
                  ? gameDetails.gameSignal.awayTeamMovementAdvantage
                  : '-'}
              </div>
              <div className="font-light">Movement</div>
              <div>
                {gameDetails.gameSignal
                  ? gameDetails.gameSignal.homeTeamMovementAdvantage
                  : '-'}
              </div>
            </div>
          </div>
        </div>
      )} */}

      {/* {isAdmin && gameDetails.gameSignal && (
        <div className="mt-4 w-full">
          <BaseButton
            config={'PRIMARY'}
            btnName={'Set Game Signals'}
            onClickHandler={() => {
              setSelectedGame({
                gameId: gameDetails.GameID,
                homeTeam: gameDetails.HomeTeam,
                awayTeam: gameDetails.AwayTeam,
                gameSignal: gameDetails.gameSignal,
              });
              setShowSignalModal(true);
            }}
          />
          <BaseButton
            btnName={'Disable Game Signal'}
            config={'SECONDARY'}
            onClickHandler={() => {
              setSelectedGame({
                gameId: gameDetails.GameID,
                homeTeam: gameDetails.HomeTeam,
                awayTeam: gameDetails.AwayTeam,
                gameSignal: gameDetails.gameSignal,
                disableSMS: true,
              });
              setShowSignalModal(true);
            }}
            disableSMS={
              !gameDetails.gameSignal.enableAwaySMS &&
              !gameDetails.gameSignal.enableHomeSMS
            }
          />
        </div>
      )} */}
    </div>
  );
};

export default UpcomingGameCard;
