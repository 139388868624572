import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useForm } from 'react-hook-form';
import { IconContext } from 'react-icons';
import { FaFileCsv } from 'react-icons/fa';
import { TailSpin } from 'react-loader-spinner';
import * as NBAIcons from 'react-nba-logos';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../../components/SideBar';
import { usePosOddsAPI } from '../../hooks/usePosOddsAPI';
import { useSportsDataAPI } from '../../hooks/useSportsDataAPI';
import { parseTeamName, setOpeningLineList } from '../../utils';

const Backtesting = () => {
  const seasonsAvailable = [2023, 2024];
  const [activeNBATeams, setActiveNBATeams] = useState([{}]);
  const [backtestingResult, setBacktestingResult] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const role = localStorage.getItem('role');

    if (role !== 'admin') {
      navigate('/dashboard');
    }
  }, []);

  const { getActiveNBATeams } = useSportsDataAPI();
  const { processBacktest } = usePosOddsAPI();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = useForm({ mode: 'all' });

  const onSubmit = async (data) => {
    setIsLoading(true);
    console.log(data);

    try {
      const res = await processBacktest(
        data.selectedSeason,
        data.selectedOpeningLine,
        data.selectedTeam
      );

      const sortedAccordingToDate = res.data.data.sort((a, b) => {
        return new Date(a.Date) - new Date(b.Date);
      });

      setBacktestingResult(sortedAccordingToDate);
      setIsLoading(false);
    } catch (error) {
      setBacktestingResult([]);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getActiveNBATeams().then((res) => {
      setActiveNBATeams(
        res.data.sort((a, b) => {
          const keyA = a.Name.toLowerCase();
          const keyB = b.Name.toLowerCase();

          if (keyA < keyB) {
            return -1;
          }
          if (keyA > keyB) {
            return 1;
          }
          return 0;
        })
      );
    });
    setValue('selectedTeam', '');
    setValue('selectedSeason', '');
    setValue('selectedOpeningLine', '');
  }, []);

  const renderBacktestingTable = () => {
    const TABLE_HEADERS = [
      'Date',
      'Team',
      'Score',
      'End Game Margin',
      'PointSpread',
      'At Spread',
      'Greatest Move',
      'Result',
      // 'Actions',
    ];

    return (
      <div className="px-16 font-light">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex w-full justify-end items-center">
            <div className="mx-4">
              <select
                name="selectedSeason"
                className="bg-main-bg-black p-2 rounded-md"
                {...register('selectedSeason', { required: true })}
              >
                <option value={''}>Season</option>
                {seasonsAvailable.map((season) => {
                  return <option value={season}>{season}</option>;
                })}
              </select>
            </div>
            <div className="flex items-center mx-4">
              <select
                name="selectedTeam"
                className="bg-main-bg-black p-2 rounded-md"
                {...register('selectedTeam', { required: true })}
              >
                <option value={''}>Team</option>
                {activeNBATeams.length > 0 &&
                  activeNBATeams.map((team, id) => {
                    return (
                      <option key={id} value={team?.Key}>
                        {team?.Name}
                      </option>
                    );
                  })}
              </select>
              {/* <div className="ml-2">Team</div> */}
            </div>
            <div className="mx-4">
              <select
                name="selectedOpeningLine"
                className="bg-main-bg-black p-2 rounded-md"
                {...register('selectedOpeningLine', { required: true })}
              >
                <option value={''}>Opening Line</option>
                {setOpeningLineList(-15, 15).map((line, id) => {
                  return (
                    <option key={id} value={line}>
                      {line}
                    </option>
                  );
                })}
              </select>
            </div>
            {backtestingResult.length > 0 && (
              <div className="my-4 mr-3">
                <CSVLink
                  data={backtestingResult}
                  filename={`Backtesting_${getValues(
                    'selectedTeam'
                  )}_${getValues('selectedSeason')}_${getValues(
                    'selectedOpeningLine'
                  )}.csv`}
                  className="flex"
                >
                  <p className="mr-2">Export</p>
                  <IconContext.Provider value={{ color: 'white' }}>
                    <FaFileCsv />
                  </IconContext.Provider>
                </CSVLink>
              </div>
            )}
            <button
              type="submit"
              className={`${
                isValid
                  ? 'text-main-dark-green bg-main-green'
                  : ' text-main-white bg-main-gray-text'
              }  w-1/4 py-3 rounded-md mx-4`}
              disabled={!isValid}
            >
              Run Test
            </button>
          </div>
        </form>

        <div className="mt-4">
          <table className="w-full h-auto text-center">
            <tbody>
              <tr>
                {TABLE_HEADERS.map((title, id) => {
                  return (
                    <th className="pb-6 text-xs" key={id}>
                      {title}
                    </th>
                  );
                })}
              </tr>
              {/* TODO: Map the columns here with tr tag */}
              {isLoading ? (
                <tr>
                  <td colSpan={8}>
                    <div className="flex h-full w-full flex-col justify-center items-center">
                      <TailSpin
                        height="120"
                        width="120"
                        color="#5AC561"
                        ariaLabel="tail-spin-loading"
                        radius="0"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={isLoading}
                      />
                    </div>
                  </td>
                </tr>
              ) : (
                backtestingResult.length > 0 &&
                backtestingResult.map((game, id) => {
                  const AwayIcon = NBAIcons[parseTeamName(game.Away_Team)];
                  const HomeIcon = NBAIcons[parseTeamName(game.Home_Team)];

                  return (
                    <tr
                      key={id}
                      className={`${
                        id % 2 === 0 ? 'bg-tb-row-gray-1' : 'bg-tb-row-gray-2'
                      } font-medium`}
                    >
                      <td className="align-middle">{game.Date}</td>
                      <td className="p-4">
                        <div className="flex justify-center items-center">
                          <AwayIcon size={40} />
                          <div className="ml-3">{game.Away_Team}</div>
                        </div>
                        <div className="flex justify-center items-center">
                          <HomeIcon size={40} />
                          <div className="ml-3">{game.Home_Team}</div>
                        </div>
                      </td>
                      <td className="align-middle">
                        <div className="my-6">{game.Final_Away_Score}</div>
                        <div className="my-6">{game.Final_Home_Score}</div>
                      </td>
                      <td className="align-middle">
                        {game.Endgame_Margin > 0
                          ? `+${game.Endgame_Margin}`
                          : game.Endgame_Margin}
                      </td>
                      <td className="align-middle">
                        {game.Pointspread > 0
                          ? `+${game.Pointspread}`
                          : game.Pointspread}
                      </td>
                      <td className="align-middle">
                        {game.At_The_Spread > 0
                          ? `+${game.At_The_Spread}`
                          : game.At_The_Spread}
                      </td>
                      <td className="align-middle">
                        {game.Greatest_Movement > 0
                          ? `+${game.Greatest_Movement}`
                          : 0}
                      </td>
                      <td className="align-middle px-4">
                        <div
                          className={`my-2 p-2 rounded-2xl ${
                            game.Endgame_Margin >
                            Number(getValues('selectedOpeningLine')) * -1
                              ? 'bg-main-green'
                              : 'bg-main-red'
                          }`}
                        >
                          {game.Endgame_Margin >
                          Number(getValues('selectedOpeningLine')) * -1
                            ? 'WIN'
                            : 'LOSS'}
                        </div>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
          <div className=" text-main-gray-text"></div>
        </div>
      </div>
    );
  };

  return (
    <div className="font-bold w-full h-screen bg-main-bg-gray text-main-white font-inter flex">
      <Sidebar />

      {/* Top Section */}
      <div className="w-full">
        <div className="flex justify-between">
          <div className="text-2xl py-4 px-16">Backtesting</div>
        </div>
        <div className="mt-10">{renderBacktestingTable()}</div>
      </div>
    </div>
  );
};

export default Backtesting;
