import { BsSearch } from 'react-icons/bs';
import Basketball from '../../assets/dashboard-icons/basketball-img.svg';

export default function SearchBar({
  pageTitle,
  searchValue,
  icon,
  publicView,
}) {
  return (
    <div className="w-full h-fit px-2 md:p-8 py-6 flex justify-between items-center text-main-white bg-main-bg-gray">
      <text className="font-semibold md:text-2xl text-xl flex items-center gap-2">
        {pageTitle}
        {icon && <img alt={'arrow-icon'} src={icon} />}
      </text>
      {/* <div className="hidden md:flex  items-center gap-6" style={{ display: publicView && 'none'}}>
        <FaBell size={"24px"} />
        <Input
          type="email"
          placeholder="Search..."
          className="!border-0 !bg-main-dark-green !text-xs text-main-white shadow-lg shadow-gray-900/5 ring-4 ring-transparent !font-light placeholder:font-light placeholder:text-secondary-white focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10"
          labelProps={{
            className: "hidden",
          }}
          containerProps={{ className: "min-w-[100px] md:min-w-[228px]" }}
          icon={<BsSearch size={"16px"} className="text-main-white" />}
        />
      </div> */}
      <div
        className={`hidden p-2 !bg-main-dark-green focus:!border-gray-900 focus:!border-t-gray-900 rounded-md`}
      >
        <BsSearch size={'16px'} className="text-main-white" />
      </div>
      <div
        className={`rounded-[100px] bg-[#ED6435] inline-flex p-[4px] h-[48px] gap-3 ${
          publicView ? 'block' : 'hidden'
        }`}
      >
        <img alt="basketball" src={Basketball} className="ml-1" />
        <a
          className="rounded-[100px] bg-main-white font-medium text-[18px] text-[#2A3635] px-3 py-1 flex items-center"
          href="https://whop.com/positive-odds/"
          target="_blank"
          rel="noreferrer"
        >
          Sign up
        </a>
      </div>
    </div>
  );
}
