import React from 'react';
import BaseButton from '../Buttons/BaseButton';
import { IconContext } from 'react-icons';
import { IoMdClose } from 'react-icons/io';
import { BsCheckCircleFill } from 'react-icons/bs';

const SuccessTeamConfigModal = ({
  isVisible,
  selectedTeam,
  onClose,
  setShowSuccessTeamConfigModal,
  onSuccess,
}) => {
  return (
    <div className="flex justify-center">
      {isVisible && (
        <div
          className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-30 z-10"
          style={{ backdropFilter: 'blur(5px)' }}
        />
      )}
      {isVisible && (
        <div className="fixed top-0 left-0 w-full h-full z-20 flex items-center justify-center text-main-white">
          <div className="w-3/4 lg:w-2/5 bg-main-bg-gray rounded-xl border p-10">
            <div
              className="modal-content rounded-card"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="flex w-full justify-end text-xl">
                <div className="cursor-pointer" onClick={onClose}>
                  <IconContext.Provider value={{ color: 'white' }}>
                    <IoMdClose />
                  </IconContext.Provider>
                </div>
              </div>

              <div className="flex flex-col text-main-white text-lg">
                <div className="flex justify-center text-4xl">
                  <IconContext.Provider value={{ color: '#5AC561' }}>
                    <BsCheckCircleFill />
                  </IconContext.Provider>
                </div>
                <div className="text-2xl flex justify-center my-2">
                  Updated Team Config!
                </div>
                <div className="flex justify-center">{selectedTeam}</div>
                <div className="flex justify-center py-10 w-full">
                  <div className="flex flex-col items-center w-full font-normal">
                    <BaseButton
                      config={'PRIMARY'}
                      type={'submit'}
                      btnName={'Close'}
                      onClickHandler={() => {
                        setShowSuccessTeamConfigModal(false);
                        onSuccess();
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SuccessTeamConfigModal;
