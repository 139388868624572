import { matchPath } from 'react-router-dom';

export const renderSidebarIcons = (route, pathname) => {
  switch (route) {
    case '/dashboard':
      return (
        <svg
          width="18"
          height="19"
          viewBox="0 0 18 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d={`M14.5589 5.96218C13.8298 5.23139 12.9632 4.65216 12.0091 4.25791C11.055 3.86366 10.0323 3.66217 9 3.66507H8.97187C4.64555 
                        3.67984 1.125 7.25101 1.125 11.6195V13.2276C1.125 13.5259 1.24353 13.8121 1.4545 14.0231C1.66548 14.234 1.95163 14.3526 2.25 
                        14.3526H15.75C16.0484 14.3526 16.3345 14.234 16.5455 14.0231C16.7565 13.8121 16.875 13.5259 16.875 13.2276V11.5401C16.8779 
                        10.5032 16.6746 9.47615 16.277 8.51858C15.8794 7.561 15.2954 6.69202 14.5589 5.96218ZM15.75 13.2276H8.41711L12.2674 7.93304C12.3553 
                        7.81239 12.3916 7.66178 12.3684 7.51436C12.3451 7.36694 12.2643 7.23478 12.1437 7.14694C12.023 7.05911 11.8724 7.02281 11.725 
                        7.04601C11.5776 7.06922 11.4454 7.15004 11.3576 7.27069L7.02562 13.2276H2.25V11.6195C2.25 11.403 2.26055 11.1892 2.28023 
                        10.9776H3.9375C4.08668 10.9776 4.22976 10.9183 4.33525 10.8128C4.44074 10.7073 4.5 10.5643 4.5 10.4151C4.5 10.2659 4.44074 
                        10.1228 4.33525 10.0173C4.22976 9.91183 4.08668 9.85257 3.9375 9.85257H2.47992C3.20555 7.12023 5.57367 5.05444 8.4375 
                        4.81398V6.47757C8.4375 6.62675 8.49676 6.76983 8.60225 6.87532C8.70774 6.98081 8.85082 7.04007 9 7.04007C9.14918 7.04007 9.29226 
                        6.98081 9.39775 6.87532C9.50324 6.76983 9.5625 6.62675 9.5625 6.47757V4.81327C10.958 4.93071 12.2825 5.47864 13.3531 6.38135C14.4237 
                        7.28407 15.1876 8.49701 15.5391 9.85257H14.0625C13.9133 9.85257 13.7702 9.91183 13.6648 10.0173C13.5593 10.1228 13.5 10.2659 13.5 10.4151C13.5 
                        10.5643 13.5593 10.7073 13.6648 10.8128C13.7702 10.9183 13.9133 10.9776 14.0625 10.9776H15.7268C15.7416 11.1639 15.75 11.3509 15.75 
                        11.5401V13.2276Z`}
            fill={`${
              matchPath({ path: '/dashboard', end: false }, pathname)
                ? '#5AC561'
                : '#FFFFFF'
            }`}
          />
        </svg>
      );
    case '/games':
      return (
        <svg
          width="18"
          height="19"
          viewBox="0 0 18 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_437_2213)">
            <path
              d={`M15.1727 8.59898C15.1514 8.50882 15.1082 8.42533 15.0469 8.3559C14.9855 8.28647 14.908 8.23327 14.8212 8.20101L10.7705 6.68156L11.8012 
                        1.52554C11.8246 1.40574 11.8083 1.2816 11.7549 1.17186C11.7015 1.06211 11.6139 0.972707 11.5052 0.917143C11.3965 0.861578 11.2727 0.842866 
                        11.1525 0.863829C11.0323 0.884792 10.9221 0.944293 10.8387 1.03335L2.96366 9.47085C2.8997 9.53825 2.85344 9.62044 2.82899 9.71008C2.80455 9.79972 
                        2.80269 9.89402 2.82358 9.98455C2.84447 10.0751 2.88746 10.159 2.94872 10.2289C3.00997 10.2988 3.08758 10.3524 3.1746 10.3849L7.22671 11.9044L6.19874 
                        17.0548C6.1754 17.1746 6.19166 17.2987 6.24506 17.4084C6.29846 17.5182 6.38611 17.6076 6.49478 17.6632C6.60345 17.7187 6.72724 17.7374 6.84747 
                        17.7165C6.96771 17.6955 7.07787 17.636 7.16132 17.5469L15.0363 9.10945C15.0991 9.04204 15.1444 8.96023 15.1682 8.87123C15.192 8.78222 15.1935 8.68873 
                        15.1727 8.59898ZM7.69007 15.337L8.42624 11.6541C8.45259 11.5234 8.43171 11.3877 8.36733 11.271C8.30295 11.1544 8.19924 11.0644 8.07468 11.017L4.35937 
                        9.62132L10.3092 3.24679L9.57374 6.92976C9.5474 7.06038 9.56827 7.19611 9.63266 7.31277C9.69704 7.42944 9.80075 7.51945 9.92531 7.56679L13.6378 8.95898L7.69007 
                        15.337Z`}
              fill={`${
                matchPath({ path: '/games', end: false }, pathname)
                  ? '#5AC561'
                  : '#FFFFFF'
              }`}
            />
          </g>
          <defs>
            <clipPath id="clip0_437_2213">
              <rect
                width="18"
                height="18"
                fill="white"
                transform="translate(0 0.290039)"
              />
            </clipPath>
          </defs>
        </svg>
      );
    case '/backtesting':
      return (
        <svg
          width="16"
          height="14"
          viewBox="0 0 16 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d={`M5.46875 6.85256H1.8125C1.65683 6.85204 1.50296 6.81921 1.36063 6.75615C1.2183 6.69309 1.0906 6.60118 0.985623 6.48624C0.879131 6.3695 0.798074 6.2319 0.747599 
                    6.08217C0.697124 5.93244 0.678333 5.77385 0.69242 5.61647C0.787939 4.56251 1.11146 3.54192 1.64056 2.6254C2.16966 1.70888 2.89169 0.918354 3.75664 0.30858C3.88441 
                    0.218206 4.02963 0.155485 4.18302 0.124427C4.33641 0.0933683 4.49459 0.0946554 4.64746 0.128206C4.80032 0.161757 4.9445 0.226833 5.07079 0.319274C5.19707 0.411715 
                    5.30268 0.529488 5.38086 0.665065L7.20898 3.82913C7.24592 3.89312 7.26989 3.96375 7.27952 4.037C7.28916 4.11026 7.28427 4.18469 7.26514 4.25605C7.24601 4.32742 
                    7.21301 4.39431 7.16803 4.45292C7.12304 4.51153 7.06696 4.56071 7.00297 4.59764C6.93898 4.63458 6.86834 4.65855 6.79509 4.66818C6.72184 4.67782 6.6474 4.67293 6.57604 
                    4.6538C6.50468 4.63467 6.43778 4.60167 6.37917 4.55669C6.32056 4.5117 6.27139 4.45562 6.23445 4.39163L4.40633 1.22756C3.67431 1.74321 3.06318 2.41187 2.61529 3.18721C2.16741 
                    3.96254 1.89348 4.82599 1.8125 5.71772C1.81236 5.71985 1.81273 5.72199 1.81359 5.72394C1.81445 5.7259 1.81576 5.72763 1.81742 5.72897L5.46875 5.72756C5.61793 5.72756 
                    5.76101 5.78683 5.8665 5.89232C5.97198 5.99781 6.03125 6.14088 6.03125 6.29006C6.03125 6.43925 5.97198 6.58232 5.8665 6.68781C5.76101 6.7933 5.61793 6.85256 5.46875 
                    6.85256ZM15.3076 5.61647C15.2121 4.56251 14.8885 3.54192 14.3594 2.6254C13.8303 1.70888 13.1083 0.918354 12.2434 0.30858C12.1156 0.218206 11.9704 0.155485 11.817 
                    0.124427C11.6636 0.0933683 11.5054 0.0946554 11.3525 0.128206C11.1997 0.161757 11.0555 0.226833 10.9292 0.319274C10.8029 0.411715 10.6973 0.529488 10.6191 
                    0.665065L8.79453 3.82913C8.7576 3.89312 8.73363 3.96375 8.72399 4.037C8.71436 4.11026 8.71924 4.18469 8.73837 4.25605C8.77701 4.40018 8.87131 4.52305 9.00054 
                    4.59764C9.12978 4.67224 9.28335 4.69244 9.42747 4.6538C9.49883 4.63467 9.56573 4.60167 9.62434 4.55669C9.68295 4.5117 9.73213 4.45562 9.76906 4.39163L11.5972 
                    1.22756C12.3284 1.7437 12.9388 2.41256 13.386 3.18784C13.8333 3.96313 14.1067 4.82633 14.1875 5.71772C14.1875 5.72264 14.1875 5.72616 14.1875 5.72756H10.5312C10.3821 
                    5.72756 10.239 5.78683 10.1335 5.89232C10.028 5.99781 9.96875 6.14088 9.96875 6.29006C9.96875 6.43925 10.028 6.58232 10.1335 6.68781C10.239 6.7933 10.3821 6.85256 
                    10.5312 6.85256H14.1875C14.3432 6.85204 14.497 6.81921 14.6394 6.75615C14.7817 6.69309 14.9094 6.60118 15.0144 6.48624C15.1209 6.3695 15.2019 6.2319 15.2524 
                    6.08217C15.3029 5.93244 15.3217 5.77385 15.3076 5.61647ZM9.75289 8.20116C9.71595 8.13717 9.66678 8.08108 9.60817 8.0361C9.54956 7.99112 9.48266 7.95812 9.4113 
                    7.93899C9.33993 7.91986 9.2655 7.91497 9.19225 7.9246C9.119 7.93424 9.04836 7.95821 8.98437 7.99514C8.92038 8.03208 8.8643 8.08125 8.81931 8.13986C8.77433 8.19847 
                    8.74133 8.26537 8.7222 8.33673C8.70307 8.4081 8.69818 8.48253 8.70782 8.55578C8.71745 8.62903 8.74142 8.69967 8.77836 8.76366L10.5952 11.9087C9.7815 12.2835 8.89624 
                    12.4776 8.00035 12.4776C7.10446 12.4776 6.2192 12.2835 5.40547 11.9087L7.22164 8.76366C7.25857 8.69967 7.28254 8.62903 7.29218 8.55578C7.30181 8.48253 7.29693 8.4081 
                    7.2778 8.33673C7.25867 8.26537 7.22567 8.19847 7.18068 8.13986C7.1357 8.08125 7.07961 8.03208 7.01562 7.99514C6.95163 7.95821 6.881 7.93424 6.80775 7.9246C6.73449 
                    7.91497 6.66006 7.91986 6.5887 7.93899C6.51733 7.95812 6.45044 7.99112 6.39183 8.0361C6.33322 8.08108 6.28404 8.13717 6.24711 8.20116L4.43164 11.3462C4.35347 
                    11.4815 4.30433 11.6316 4.28735 11.7869C4.27037 11.9422 4.28592 12.0993 4.33302 12.2483C4.38012 12.3972 4.45772 12.5348 4.56089 12.6521C4.66407 12.7694 4.79054 
                    12.8639 4.93226 12.9297C5.89421 13.373 6.94081 13.6026 8 13.6026C9.05918 13.6026 10.1058 13.373 11.0677 12.9297C11.2095 12.8639 11.3359 12.7694 11.4391 
                    12.6521C11.5423 12.5348 11.6199 12.3972 11.667 12.2483C11.7141 12.0993 11.7296 11.9422 11.7126 11.7869C11.6957 11.6316 11.6465 11.4815 11.5684 11.3462L9.75289 
                    8.20116ZM8 7.13381C8.16688 7.13381 8.33001 7.08433 8.46876 6.99162C8.60751 6.8989 8.71566 6.76713 8.77952 6.61295C8.84338 6.45878 8.86009 6.28913 8.82754 
                    6.12546C8.79498 5.96179 8.71462 5.81144 8.59662 5.69344C8.47862 5.57544 8.32828 5.49508 8.16461 5.46253C8.00093 5.42997 7.83128 5.44668 7.67711 5.51054C7.52293 
                    5.5744 7.39116 5.68255 7.29845 5.8213C7.20573 5.96006 7.15625 6.12319 7.15625 6.29006C7.15625 6.51384 7.24514 6.72845 7.40338 6.88669C7.56161 7.04492 7.77622 
                    7.13381 8 7.13381Z`}
            fill={`${
              matchPath({ path: '/backtesting', end: false }, pathname)
                ? '#5AC561'
                : '#FFFFFF'
            }`}
          />
        </svg>
      );
    case '/team-manager':
      return (
        <svg
          width="18"
          height="19"
          viewBox="0 0 18 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.06705 0L5.52258 0.135922C5.5735 1.96792 5.69493 3.88297 5.6205 5.55555C5.54216 7.32997 5.27189 8.86545 4.3318 9.73503V18H13.4977V9.73503C12.5576 8.86545 
                    12.2873 7.32997 12.209 5.55555C12.1346 3.88297 12.256 1.96792 12.3069 0.135922L11.7624 0C11.7193 0.864884 11.5548 1.80262 11.1945 2.59504C10.7714 3.52652 10.0115 4.29034 8.91474 
                    4.29034C7.81797 4.29034 7.05806 3.52652 6.63502 2.59504C6.27465 1.80262 6.11013 0.864884 6.06705 0ZM6.77995 0.130438C6.83479 0.881727 6.99147 1.67689 7.27741 
                    2.30361C7.63778 3.09564 8.13133 3.58527 8.91474 3.58527C9.69815 3.58527 10.1917 3.09564 10.5521 2.30361C10.838 1.67689 10.9947 0.881727 11.0495 0.130438C10.1251 
                    0.587557 9.54147 0.84334 8.91474 0.84334C8.28801 0.84334 7.70437 0.587557 6.77995 0.130438ZM4.82143 0.31023L4.25737 0.451244C4.27304 1.92484 4.40622 3.54218 
                    4.3318 5.05025C4.25345 6.59748 3.94793 8.0507 3 9.08089V18H3.62673V9.40992L3.75991 9.30416C4.49631 8.70877 4.83709 7.3143 4.91544 5.52421C4.98594 3.95347 
                    4.87626 2.1109 4.82143 0.31023ZM13.0081 0.31023C12.9532 2.1109 12.8435 3.95347 12.914 5.52421C12.9924 7.3143 13.3332 8.70877 14.0696 9.30416L14.2028 
                    9.40992V18H14.8295V9.08089C13.8816 8.0507 13.576 6.59748 13.4977 5.05025C13.4233 3.54218 13.5564 1.92484 13.5721 0.451244L13.0081 0.31023ZM7.61036 
                    8.57559C7.56728 8.69702 7.48893 8.87328 7.37142 9.10831L6.56059 10.585H7.59078V9.22582C7.59078 9.10831 7.59078 8.97904 7.5947 8.84195C7.60253 8.70485 
                    7.60645 8.61476 7.61036 8.57559Z"
            fill={`${
              matchPath({ path: '/team-manager', end: false }, pathname)
                ? '#5AC561'
                : '#FFFFFF'
            }`}
          />
        </svg>
      );
    case '/all-users':
      return (
        <svg
          width="18"
          height="19"
          viewBox="0 0 18 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d={`M8.24415 11.3935C8.99804 10.8917 9.5704 10.1605 9.87659 9.30812C10.1828 8.45577 10.2066 7.52751 9.94438 6.6606C9.68221 5.7937 9.14801 5.03418 8.42079 
                    4.49436C7.69357 3.95455 6.81193 3.66309 5.90625 3.66309C5.00058 3.66309 4.11894 3.95455 3.39172 4.49436C2.6645 5.03418 2.1303 5.7937 1.86813 6.6606C1.60596 
                    7.52751 1.62972 8.45577 1.93592 9.30812C2.24211 10.1605 2.81447 10.8917 3.56836 11.3935C2.20465 11.8962 1.04 12.8263 0.248208 14.045C0.206594 14.1069 
                    0.177688 14.1764 0.163172 14.2495C0.148656 14.3226 0.148819 14.3979 0.163651 14.471C0.178482 14.544 0.207687 14.6134 0.249568 14.6751C0.291448 14.7367 
                    0.345169 14.7895 0.407608 14.8302C0.470046 14.8709 0.539957 14.8988 0.613276 14.9123C0.686595 14.9257 0.76186 14.9245 0.834695 14.9086C0.907531 14.8928 
                    0.976485 14.8626 1.03755 14.8198C1.09861 14.777 1.15057 14.7226 1.1904 14.6596C1.70114 13.874 2.40002 13.2285 3.22358 12.7816C4.04713 12.3348 4.96927 
                    12.1007 5.90625 12.1007C6.84324 12.1007 7.76538 12.3348 8.58893 12.7816C9.41249 13.2285 10.1114 13.874 10.6221 14.6596C10.7046 14.7822 10.8321 14.8674 
                    10.9769 14.8968C11.1217 14.9262 11.2723 14.8974 11.3961 14.8167C11.5199 14.736 11.6069 14.6097 11.6384 14.4653C11.6699 14.3209 11.6433 14.17 11.5643 
                    14.045C10.7725 12.8263 9.60786 11.8962 8.24415 11.3935ZM2.8125 7.88355C2.8125 7.27166 2.99395 6.67352 3.3339 6.16475C3.67384 5.65599 4.15702 5.25945 
                    4.72233 5.0253C5.28764 4.79114 5.90969 4.72987 6.50982 4.84924C7.10994 4.96862 7.6612 5.26327 8.09387 5.69594C8.52654 6.1286 8.82119 6.67986 8.94056 
                    7.27999C9.05993 7.88012 8.99866 8.50217 8.76451 9.06747C8.53035 9.63278 8.13381 10.116 7.62505 10.4559C7.11629 10.7959 6.51814 10.9773 5.90625 
                    10.9773C5.08603 10.9764 4.29966 10.6501 3.71967 10.0701C3.13968 9.49014 2.81343 8.70378 2.8125 7.88355ZM17.588 14.8234C17.463 14.9049 17.3108 
                    14.9334 17.1649 14.9027C17.0189 14.8719 16.8911 14.7845 16.8096 14.6596C16.2995 13.8736 15.6007 13.2278 14.777 12.7811C13.9532 12.3344 13.0308 
                    12.101 12.0938 12.1023C11.9446 12.1023 11.8015 12.043 11.696 11.9375C11.5905 11.8321 11.5313 11.689 11.5313 11.5398C11.5313 11.3906 11.5905 11.2475 
                    11.696 11.142C11.8015 11.0366 11.9446 10.9773 12.0938 10.9773C12.5494 10.9769 12.9992 10.8758 13.4113 10.6814C13.8233 10.4869 14.1873 10.2039 14.4772 
                    9.85242C14.7672 9.50099 14.9759 9.08985 15.0886 8.64839C15.2012 8.20693 15.2149 7.74604 15.1288 7.29865C15.0427 6.85126 14.8588 6.42842 14.5903 
                    6.06034C14.3218 5.69226 13.9753 5.38802 13.5756 5.16937C13.1759 4.95071 12.7328 4.82304 12.2781 4.79547C11.8233 4.7679 11.368 4.84111 10.9448 
                    5.00988C10.8758 5.0397 10.8016 5.0554 10.7264 5.05603C10.6512 5.05667 10.5767 5.04223 10.5072 5.01357C10.4377 4.98492 10.3747 4.94262 10.3218 
                    4.88918C10.2689 4.83575 10.2273 4.77226 10.1994 4.70246C10.1715 4.63267 10.1578 4.55798 10.1592 4.48283C10.1607 4.40767 10.1772 4.33356 10.2077 
                    4.26488C10.2383 4.1962 10.2823 4.13434 10.3372 4.08296C10.392 4.03158 10.4567 3.99172 10.5272 3.96574C11.4957 3.57947 12.573 3.56557 13.5511 
                    3.92672C14.5293 4.28786 15.3391 4.99845 15.8243 5.92142C16.3094 6.84438 16.4356 7.91432 16.1784 8.92482C15.9213 9.93532 15.299 10.8148 14.4316 
                    11.3935C15.7954 11.8962 16.96 12.8263 17.7518 14.045C17.8333 14.17 17.8618 14.3222 17.8311 14.4681C17.8003 14.6141 17.7129 14.7419 17.588 
                    14.8234Z`}
            fill={`${
              matchPath({ path: '/all-users', end: false }, pathname)
                ? '#5AC561'
                : '#FFFFFF'
            }`}
          />
        </svg>
      );
    case '/send-sms':
      return (
        <svg
          width="18"
          height="19"
          viewBox="0 0 18 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d={`M9.28125 1.97729C7.41707 1.97934 5.62984 2.72079 4.31167 4.03896C2.99349 5.35713 2.25205 7.14437 2.25 9.00854V14.938C2.25037 15.2301 2.36657 
                    15.5101 2.57312 15.7167C2.77967 15.9232 3.0597 16.0394 3.3518 16.0398H9.28125C11.1461 16.0398 12.9345 15.299 14.2531 13.9804C15.5717 12.6618 
                    16.3125 10.8733 16.3125 9.00854C16.3125 7.14374 15.5717 5.35532 14.2531 4.0367C12.9345 2.71809 11.1461 1.97729 9.28125 1.97729ZM9.28125 
                    14.9148H3.375V9.00854C3.375 7.8404 3.7214 6.69849 4.37038 5.72721C5.01937 4.75593 5.9418 3.99891 7.02103 3.55188C8.10025 3.10485 9.2878 2.98789 
                    10.4335 3.21578C11.5792 3.44368 12.6316 4.00619 13.4576 4.8322C14.2836 5.6582 14.8461 6.71059 15.074 7.85629C15.3019 9.00199 15.1849 
                    10.1895 14.7379 11.2688C14.2909 12.348 13.5339 13.2704 12.5626 13.9194C11.5913 14.5684 10.4494 14.9148 9.28125 14.9148ZM10.125 9.28979C10.125 
                    9.45667 10.0755 9.6198 9.9828 9.75856C9.89009 9.89731 9.75831 10.0055 9.60414 10.0693C9.44996 10.1332 9.28031 10.1499 9.11664 10.1173C8.95297 
                    10.0848 8.80263 10.0044 8.68463 9.88642C8.56663 9.76842 8.48627 9.61807 8.45371 9.4544C8.42116 9.29073 8.43787 9.12108 8.50173 8.96691C8.56559 
                    8.81273 8.67373 8.68095 8.81249 8.58824C8.95124 8.49553 9.11437 8.44604 9.28125 8.44604C9.50503 8.44604 9.71964 8.53494 9.87787 8.69317C10.0361 
                    8.85141 10.125 9.06602 10.125 9.28979ZM7.03125 9.28979C7.03125 9.45667 6.98177 9.6198 6.88905 9.75856C6.79634 9.89731 6.66456 10.0055 6.51039 
                    10.0693C6.35621 10.1332 6.18656 10.1499 6.02289 10.1173C5.85922 10.0848 5.70888 10.0044 5.59088 9.88642C5.47288 9.76842 5.39252 9.61807 5.35996 
                    9.4544C5.32741 9.29073 5.34412 9.12108 5.40798 8.96691C5.47184 8.81273 5.57998 8.68095 5.71874 8.58824C5.85749 8.49553 6.02062 8.44604 6.1875 
                    8.44604C6.41128 8.44604 6.62589 8.53494 6.78412 8.69317C6.94236 8.85141 7.03125 9.06602 7.03125 9.28979ZM13.2188 9.28979C13.2188 9.45667 
                    13.1693 9.6198 13.0766 9.75856C12.9838 9.89731 12.8521 10.0055 12.6979 10.0693C12.5437 10.1332 12.3741 10.1499 12.2104 10.1173C12.0467 
                    10.0848 11.8964 10.0044 11.7784 9.88642C11.6604 9.76842 11.58 9.61807 11.5475 9.4544C11.5149 9.29073 11.5316 9.12108 11.5955 8.96691C11.6593 
                    8.81273 11.7675 8.68095 11.9062 8.58824C12.045 8.49553 12.2081 8.44604 12.375 8.44604C12.5988 8.44604 12.8134 8.53494 12.9716 8.69317C13.1299 
                    8.85141 13.2188 9.06602 13.2188 9.28979Z`}
            fill={`${
              matchPath({ path: '/send-sms', end: false }, pathname)
                ? '#5AC561'
                : '#FFFFFF'
            }`}
          />
        </svg>
      );
      case '/faq':
        return (
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.75 14.0399V15.5399H7.25V14.0399H1.25C1.05109 14.0399 0.860322 13.9609 0.71967 13.8202C0.579018 13.6795 0.5 13.4888 0.5 13.2899V1.28987C0.5 
                    1.09096 0.579018 0.900192 0.71967 0.759539C0.860322 0.618887 1.05109 0.53987 1.25 0.53987H5.75C6.17569 0.539364 6.59659 0.629687 6.98459 0.804805C7.37259 
                    0.979923 7.71877 1.23581 8 1.55537C8.28123 1.23581 8.62741 0.979923 9.01541 0.804805C9.40341 0.629687 9.82431 0.539364 10.25 0.53987H14.75C14.9489 0.53987 
                    15.1397 0.618887 15.2803 0.759539C15.421 0.900192 15.5 1.09096 15.5 1.28987V13.2899C15.5 13.4888 15.421 13.6795 15.2803 13.8202C15.1397 13.9609 14.9489 14.0399 
                    14.75 14.0399H8.75ZM14 12.5399V2.03987H10.25C9.85218 2.03987 9.47064 2.1979 9.18934 2.47921C8.90804 2.76051 8.75 3.14204 8.75 3.53987V12.5399H14ZM7.25 
                    12.5399V3.53987C7.25 3.14204 7.09196 2.76051 6.81066 2.47921C6.52936 2.1979 6.14782 2.03987 5.75 2.03987H2V12.5399H7.25Z" 
                    fill="white"/>
          </svg>
        );
        case '/contact-us':
          return (
            <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.28125 0.977371C5.41707 0.979418 3.62984 1.72087 2.31167 3.03904C0.993495 4.35721 0.252047 6.14445 0.25 8.00862V13.9381C0.250372 14.2302 0.366573 
                    14.5102 0.57312 14.7168C0.779666 14.9233 1.0597 15.0395 1.3518 15.0399H7.28125C9.14605 15.0399 10.9345 14.2991 12.2531 12.9805C13.5717 11.6619 14.3125 9.87342 
                    14.3125 8.00862C14.3125 6.14382 13.5717 4.35539 12.2531 3.03678C10.9345 1.71816 9.14605 0.977371 7.28125 0.977371ZM7.28125 13.9149H1.375V8.00862C1.375 6.84048 
                    1.7214 5.69856 2.37038 4.72728C3.01937 3.75601 3.9418 2.99899 5.02103 2.55196C6.10025 2.10493 7.2878 1.98796 8.4335 2.21586C9.5792 2.44375 10.6316 3.00627 11.4576 
                    3.83227C12.2836 4.65828 12.8461 5.71067 13.074 6.85637C13.3019 8.00207 13.1849 9.18962 12.7379 10.2688C12.2909 11.3481 11.5339 12.2705 10.5626 12.9195C9.59131 
                    13.5685 8.4494 13.9149 7.28125 13.9149ZM8.125 8.28987C8.125 8.45675 8.07551 8.61988 7.9828 8.75863C7.89009 8.89739 7.75831 9.00553 7.60414 9.06939C7.44996 9.13326 
                    7.28031 9.14997 7.11664 9.11741C6.95297 9.08485 6.80263 9.00449 6.68463 8.88649C6.56663 8.76849 6.48627 8.61815 6.45371 8.45448C6.42116 8.29081 6.43787 8.12116 
                    6.50173 7.96698C6.56559 7.81281 6.67373 7.68103 6.81249 7.58832C6.95124 7.49561 7.11437 7.44612 7.28125 7.44612C7.50503 7.44612 7.71964 7.53502 7.87787 7.69325C8.0361 
                    7.85148 8.125 8.06609 8.125 8.28987ZM5.03125 8.28987C5.03125 8.45675 4.98177 8.61988 4.88905 8.75863C4.79634 8.89739 4.66456 9.00553 4.51039 9.06939C4.35621 9.13326 
                    4.18656 9.14997 4.02289 9.11741C3.85922 9.08485 3.70888 9.00449 3.59088 8.88649C3.47288 8.76849 3.39252 8.61815 3.35996 8.45448C3.32741 8.29081 3.34412 8.12116 
                    3.40798 7.96698C3.47184 7.81281 3.57998 7.68103 3.71874 7.58832C3.85749 7.49561 4.02062 7.44612 4.1875 7.44612C4.41128 7.44612 4.62589 7.53502 4.78412 7.69325C4.94236 
                    7.85148 5.03125 8.06609 5.03125 8.28987ZM11.2188 8.28987C11.2188 8.45675 11.1693 8.61988 11.0766 8.75863C10.9838 8.89739 10.8521 9.00553 10.6979 9.06939C10.5437 9.13326 
                    10.3741 9.14997 10.2104 9.11741C10.0467 9.08485 9.89638 9.00449 9.77838 8.88649C9.66038 8.76849 9.58002 8.61815 9.54746 8.45448C9.51491 8.29081 9.53162 8.12116 
                    9.59548 7.96698C9.65934 7.81281 9.76748 7.68103 9.90624 7.58832C10.045 7.49561 10.2081 7.44612 10.375 7.44612C10.5988 7.44612 10.8134 7.53502 10.9716 7.69325C11.1299 
                    7.85148 11.2188 8.06609 11.2188 8.28987Z" 
                    fill="white"/>
            </svg>
        );
    default:
      break;
  }
};
