import React from 'react';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  IconButton,
  List,
  ListItem,
  ListItemPrefix,
  MobileNav,
  Navbar,
  Typography,
} from '@material-tailwind/react';
import CompanyLogo from '../../assets/dashboard-icons/pos-logo-header.svg';
import MenuIcon from '../../assets/dashboard-icons/menu-lines.svg';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { matchPath, useNavigate } from 'react-router-dom';
import { renderSidebarIcons } from '../SidebarIcons';
import { InboxIcon } from '@heroicons/react/24/solid';
import { useAuth0 } from '@auth0/auth0-react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';

const NavBar = () => {
  const [openNav, setOpenNav] = React.useState(false);
  const [open, setOpen] = React.useState(0);
  const [isAdmin, setIsAdmin] = React.useState(false);
  const navigate = useNavigate();
  const pathname = window.location.pathname;

  const { logout, user } = useAuth0();

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  const navList = (
    <List>
      <ListItem
        className={`${
          pathname === '/dashboard' ? 'text-main-green' : 'text-main-white'
        }`}
        onClick={() => navigate('/dashboard')}
      >
        <ListItemPrefix>
          {renderSidebarIcons('/dashboard', pathname)}
        </ListItemPrefix>
        Dashboard
      </ListItem>
      <Accordion
        open={open === 1}
        icon={
          <ChevronDownIcon
            strokeWidth={2.5}
            className={`mx-auto h-4 w-4 transition-transform text-main-white ${
              open === 1 ? 'rotate-180' : ''
            }`}
          />
        }
      >
        <ListItem className="p-0" selected={open === 1}>
          <AccordionHeader
            onClick={() => handleOpen(1)}
            className="border-b-0 py-3 px-3 text-xs"
          >
            <ListItemPrefix>
              {renderSidebarIcons('/games', pathname)}
            </ListItemPrefix>
            <ListItem
              className={`mr-auto p-0 ${
                matchPath({ path: '/games', end: false }, pathname)
                  ? 'text-main-green'
                  : 'text-main-white'
              }`}
            >
              All Games
            </ListItem>
          </AccordionHeader>
        </ListItem>
        <AccordionBody className="py-1 min-w-full">
          <List className="p-0 text-main-white min-w-full">
            <ListItem
              className="flex justify-start w-full py-1 font-light"
              onClick={() => navigate('/games/live')}
            >
              In Progress
            </ListItem>
            <ListItem
              className="flex justify-start w-full py-1 font-light"
              onClick={() => navigate('/games/scheduled')}
            >
              Scheduled
            </ListItem>
            <ListItem
              className="flex justify-start w-full py-1 font-light"
              onClick={() => navigate('/games/last-night')}
            >
              Last Night
            </ListItem>
          </List>
        </AccordionBody>
      </Accordion>
      {isAdmin && (
        <ListItem
          className={`${
            pathname === '/backtesting' ? 'text-main-green' : 'text-main-white'
          } text-xs`}
          onClick={() => navigate('/backtesting')}
        >
          <ListItemPrefix>
            {renderSidebarIcons('/backtesting', pathname)}
          </ListItemPrefix>
          Backtesting
        </ListItem>
      )}

      <div>
        <hr className="my-2 border-t-[1.8px] border-t-blue-gray-300" />
      </div>
      {isAdmin && (
        <ListItem
          className={`${
            pathname === '/team-manager' ? 'text-main-green' : 'text-main-white'
          } text-xs`}
          onClick={() => navigate('/team-manager')}
        >
          <ListItemPrefix>
            {renderSidebarIcons('/team-manager', pathname)}
          </ListItemPrefix>
          Team manager
        </ListItem>
      )}
      {isAdmin && (
        <ListItem
          className={`${
            pathname === '/all-users' ? 'text-main-green' : 'text-main-white'
          } text-xs`}
          onClick={() => navigate('/all-users')}
        >
          <ListItemPrefix>
            {renderSidebarIcons('/all-users', pathname)}
          </ListItemPrefix>
          All Users
        </ListItem>
      )}
      {isAdmin && (
        <ListItem
          className={`${
            pathname === '/send-sms' ? 'text-main-green' : 'text-main-white'
          } text-xs`}
          onClick={() => navigate('/send-sms')}
        >
          <ListItemPrefix>
            {renderSidebarIcons('/send-sms', pathname)}
          </ListItemPrefix>
          SMS
        </ListItem>
      )}
      <ListItem
        className={`${
          pathname.includes('/get-started')
            ? 'text-main-green'
            : 'text-main-white'
        } text-xs`}
        onClick={() => {
          localStorage.setItem('isLoggedIn', false);
          localStorage.setItem('role', undefined);
          logout({
            logoutParams: {
              returnTo: `${window.location.origin}/admin/login`,
            },
          });
        }}
      >
        <ListItemPrefix>
          <InboxIcon className="h-5 w-5" />
        </ListItemPrefix>
        Logout
      </ListItem>
    </List>
  );

  React.useEffect(() => {
    const role = localStorage.getItem('role');

    if (role === 'admin') {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, []);

  return (
    <Navbar className="sticky w-full lg:hidden block items-center bg-card-bg top-0 z-10 max-w-full rounded-none py-2 px-4 lg:px-8 lg:py-4 h-20">
      <div className="flex items-center justify-between text-blue-gray-900 h-full">
        <img alt="app-logo" src={CompanyLogo} className="h-9 w-28" />
        <IconButton
          variant="text"
          className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
          ripple={false}
          onClick={() => setOpenNav(!openNav)}
        >
          {openNav ? (
            <AiOutlineClose size={'24px'} className="text-main-white" />
          ) : (
            <AiOutlineMenu size={'24px'} className="text-main-white" />
          )}
        </IconButton>
      </div>
      <MobileNav
        className={`sticky w-full lg:hidden block bg-card-bg top-0 z-10 h-max max-w-full rounded-none px-0 lg:px-8 lg:py-4 ${
          openNav && 'mt-3'
        } `}
        open={openNav}
      >
        <div className="container mx-auto">{navList}</div>
      </MobileNav>
    </Navbar>
  );
};

export default NavBar;
