import React from 'react';
import { TailSpin } from 'react-loader-spinner';

const LoaderModal = ({ isVisible }) => {
  return (
    <div className="flex justify-center">
      {isVisible && (
        <div
          className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-30 z-10"
          style={{ backdropFilter: 'blur(5px)' }}
        />
      )}
      {isVisible && (
        <div className="fixed top-0 left-0 w-full h-full z-20 flex items-center justify-center">
          <div
            className="modal-content rounded-card"
            onClick={(e) => e.stopPropagation()}
          >
            <TailSpin
              height="120"
              width="120"
              color="#5AC561"
              ariaLabel="tail-spin-loading"
              radius="0"
              wrapperStyle={{}}
              wrapperClass=""
              visible={isVisible}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default LoaderModal;
