import React from 'react';
import TodayGameCard from '../../components/GameCard/PublicGameCards/TodayGameCard';

const TodayGames = (schedGames) => {
  return schedGames.length > 0 ? (
    schedGames?.map((game, id) => {
      return (
        <div key={id}>
          <TodayGameCard key={game.GameID} game={game} />
        </div>
      );
    })
  ) : (
    <div className="w-full text-white text-center my-10">No games today</div>
  );
};

export default function TodayGameCards({ data, orientation, backgroundCard }) {
  return (
    <div className="w-full">
      <div className="flex items-center gap-2 mb-2">
        <text className="text-main-white font-semibold text-lg">
          {' '}
          Today's Games
        </text>
      </div>
      <div
        className={`grid lg:gap-6 md:gap-6 gap-8 items-start w-full md:grid-cols-2 grid-col-1 bg-[#282E28] p-5 rounded-[20px]`}
      >
        {TodayGames(data)}
      </div>
    </div>
  );
}
