import React, { useState, useEffect } from 'react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
} from '@tanstack/react-table';
import SideBar from '../../components/SideBar';
import { usePosOddsAPI } from '../../hooks/usePosOddsAPI';
import { isSubscriptionValid } from '../../utils';
import moment from 'moment-timezone';
import { CSVLink } from 'react-csv';
import { TailSpin } from 'react-loader-spinner';
import OldPagination from '../../components/Pagination';
import { AiOutlineSearch, AiOutlineClose } from 'react-icons/ai';

const AllUsers = () => {
  const [data, setData] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [globalFilter, setGlobalFilter] = useState('');
  const [searchInput, setSearchInput] = useState('');

  const { getUserList } = usePosOddsAPI();

  const handleGetUserList = async () => {
    setIsLoading(true);
    try {
      const result = await getUserList(0, 1000);
      const users = result.data.users.filter(
        (item) =>
          item.subscription_expire_date !== null &&
          item.email !== 'rajkrajpj@gmail.com'
        // isSubscriptionValid(item.subscription_expire_date)
      );

      const updatedUsers = users
        .map((user) => ({
          ...user,
          full_name: `${user.first_name} ${user.last_name}`,
        }))
        .sort(
          (a, b) =>
            new Date(b.registered_on).getTime() -
            new Date(a.registered_on).getTime()
        );

      setData(updatedUsers);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleGetUserList();
  }, []);

  const parsePlan = (plan) => {
    // NOTE: update once variable name for "plan" is final from the DB
    switch (plan) {
      case 'monthly-daily-better':
      case 'DAILY_BETTER_WEEKLY':
      case 'MONTHLY_BETTER_WEEKLY':
      case 'YEARLY_BETTER_WEEKLY':
        return { name: 'Monthly Basic', style: `bg-cyan-700` };
      case 'PREMIUM_MONTHLY':
      case 'monthly-premium':
        return { name: 'Monthly Premium', style: `bg-mantis-700` };
      case 'PREMIUM_YEARLY':
      case 'yearly-premium':
        return { name: 'Yearly Premium', style: `bg-mantis-700` };
      case 'YEARLY_BETTER_WEEKLY':
      case 'DAILY_BETTER_YEARLY':
      case 'yearly-daily-better':
        return { name: 'Yearly Basic', style: `bg-cyan-700` };
      default:
        return { name: 'Monthly Basic', style: `bg-cyan-700` };
    }
  };

  const columns = [
    columnHelper.accessor('email', {
      id: 'email',
      header: () => <p className="text-xs font-normal text-white">Email</p>,
      cell: (info) => (
        <p className="text-sm font-normal text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor('full_name', {
      id: 'full_name',
      header: () => <p className="text-xs font-normal text-white">Name</p>,
      cell: (info) => (
        <p className="text-xs font-normal text-white">{info.getValue()}</p>
      ),
    }),
    columnHelper.accessor('nickname', {
      id: 'nickname',
      header: () => <p className="text-xs font-normal text-white">Username</p>,
      cell: (info) => (
        <p className="text-sm font-normal text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor('plan', {
      id: 'plan',
      header: () => <p className="text-xs font-normal text-white">Plan</p>,
      cell: (info) => (
        <p
          className={`text-xs font-normal text-center leading-tight rounded-md p-2 dark:text-white text-white uppercase whitespace-pre-line w-28 ${
            parsePlan(info.getValue())?.style
          }`}
        >
          {parsePlan(info.getValue())?.name}
        </p>
      ),
    }),
    columnHelper.accessor('phone', {
      id: 'phone',
      header: () => <p className="text-xs font-normal text-white">Phone</p>,
      cell: (info) => (
        <p className="text-sm font-normal text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor('registered_on', {
      id: 'registered_on',
      header: () => (
        <p className="text-xs font-normal text-white">Date of Signup</p>
      ),
      cell: (info) => (
        <p className="text-sm font-normal text-navy-700 dark:text-white">
          {moment(info.getValue()).format('MMM D, YYYY')}
        </p>
      ),
    }),
    columnHelper.accessor('date_last_paid_subscription', {
      id: 'date_last_paid_subscription',
      header: () => (
        <p className="text-xs font-normal text-white">Date Start</p>
      ),
      cell: (info) => (
        <p className="text-sm font-normal text-navy-700 dark:text-white">
          {moment(info.getValue()).format('MMM D, YYYY, h:mm a')}
        </p>
      ),
    }),
    columnHelper.accessor('subscription_expire_date', {
      id: 'subscription_expire_date',
      header: () => (
        <p className="text-xs font-normal text-white">Expiration</p>
      ),
      cell: (info) => (
        <p className="text-sm font-normal text-navy-700 dark:text-white">
          {moment(info.getValue()).format('MMM D, YYYY, h:mm a')}
        </p>
      ),
    }),
  ];
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      globalFilter,
    },
    useGlobalFilter: true,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    debugTable: true,
    enableGlobalFilter: true,
    onGlobalFilterChange: setGlobalFilter,
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  });

  const handleSearch = () => {
    if (searchInput.toLowerCase().includes('basic')) {
      setGlobalFilter('DAILY_BETTER_YEARLY');
    } else if (
      searchInput.toLowerCase().includes('yearly') &&
      searchInput.toLowerCase().includes('basic')
    ) {
      setGlobalFilter('DAILY_BETTER_YEARLY');
    } else if (
      searchInput.toLowerCase().includes('yearly') &&
      searchInput.toLowerCase().includes('premium')
    ) {
      setGlobalFilter('PREMIUM_YEARLY');
    } else {
      setGlobalFilter(searchInput);
    }
  };

  const handleClear = () => {
    setSearchInput('');
    setGlobalFilter('');
  };

  return (
    <div className="font-bold w-full h-screen bg-main-bg-gray text-main-white font-inter flex">
      <SideBar />

      <div className="pl-16 pr-6 w-4/5">
        <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
          <div className="text-2xl">All Users</div>
          <div className="flex justify-end">
            <div className="flex text-gray-400 bg-main-dark-green rounded items-center pr-3">
              <input
                className={`w-full p-4 h-[46px] font-normal text-base text-gray-400 bg-main-dark-green rounded border-gray-400 sm:text-sm focus:border-gray-400 border-solid focus:outline-none py-1`}
                onChange={(e) => setSearchInput(e.target.value)}
                value={searchInput}
              />
              {searchInput !== '' && (
                <AiOutlineClose
                  className="text-lg cursor-pointer pr-1"
                  onClick={handleClear}
                />
              )}
              <AiOutlineSearch
                className="text-lg cursor-pointer"
                onClick={handleSearch}
              />
            </div>
            <CSVLink
              data={data}
              filename={`Users_${moment().format('MMM_D_YYYY')}.csv`}
              className="flex"
            >
              <button
                type="submit"
                className={`text-main-dark-green font-medium bg-main-green w-fit px-4 py-3 rounded-md mx-4`}
              >
                Export
              </button>
            </CSVLink>
          </div>
          <div className="w-full overflow-x-auto mr-4 mb-12 mt-5">
            <table
              className="w-full mt-2 shadow-lg border-collapse"
              style={{ border: '1px solid #2A3635' }}
              hidden={isLoading}
            >
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr
                    key={headerGroup.id}
                    className="!border-px !border-gray-400"
                  >
                    {headerGroup.headers.map((header) => {
                      return (
                        <th
                          key={header.id}
                          colSpan={header.colSpan}
                          onClick={header.column.getToggleSortingHandler()}
                          className="cursor-pointer border-b-[1px] border-gray-200 pl-4 pt-4 pb-2 pr-4 text-start"
                        >
                          <div className="items-center justify-between text-xs text-gray-200">
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: '',
                              desc: '',
                            }[header.column.getIsSorted()] ?? null}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => {
                  return (
                    <tr
                      className={`${
                        row.id % 2 === 0
                          ? 'bg-tb-row-gray-1'
                          : 'bg-tb-row-gray-2'
                      }`}
                      key={row.id}
                    >
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <td
                            key={cell.id}
                            className="min-w-[150px] border-white/0 py-2 pr-4 pl-4 align-middle"
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {table.getRowModel().rows &&
              table.getRowModel().rows.length > 0 && (
                <OldPagination table={table} />
              )}
          </div>
          <div
            hidden={!isLoading}
            className="flex h-full w-11/12 justify-center items-center"
          >
            <TailSpin
              height="120"
              width="120"
              color="#5AC561"
              ariaLabel="tail-spin-loading"
              radius="0"
              wrapperStyle={{}}
              wrapperClass=""
              visible={isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllUsers;
const columnHelper = createColumnHelper();
