import styled from 'styled-components';
import {
  AlgoLine,
  GraphArrowUp,
  AlertWifi,
  MachineBrain,
  AppsIcon,
  TouchedTablet,
} from '../../../assets/subs-feature-icons';

export default function InfoCard(props) {
  return (
    <div className="w-full flex justify-center items-center flex-col my-20">
      <div className="text-main-white w-full px-7 sm:px-16 text-2xl text-center lg:text-center sm:text-center font-semibold pb-[38px] sm:text-2xl lg:text-[36px]">
        Are you ready to elevate your NBA betting game to new heights?
      </div>
      <span className="font-light text-sm text-white w-4/5 text-center">
        Introducing our cutting-edge algorithmic NBA betting platform,
        meticulously crafted and fine-tuned over three years of intensive
        development. Formerly an exclusive tool reserved for high net worth
        individuals, we’re thrilled to make it available to the public, offering
        you the opportunity to conservatively increase your bankroll by 350% or
        more.
      </span>
      <span className="font-light text-sm text-white w-4/5 text-center mt-6">
        You now have the opportunity to take advantage of the extensive research
        and development that went into creating our algorithmic NBA betting
        platform. Join us now and experience the power of date-driven betting,
        consistently increasing your bankroll while minimizing risk. Don't miss
        the opportunity to gain the edge in NBA betting with our platform--your
        path to financial growth starts here!
      </span>
      <div className="w-4/5 grid lg:grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 sm:grid-cols-1 items-center text-start pt-9 p-4 text-white sm:block gap-4 leading-normal">
        <div className="text-left rounded-md bg-card-bg grid gap-3 p-5 min-h-[342px]">
          <img alt="Logo" src={AlgoLine} />
          <span className="font-semibold text-lg">Algorithmic precision</span>
          <span className="font-light text-sm">
            Our platform leverages advanced algorithms designed to analyze vast
            amounts of historical data, player statistics, team dynamics, and
            in-game factors. This intricate analysis allows us to make informed,
            data-driven predictions, helping you make smarter betting decisions.
          </span>
        </div>
        <div className="text-left rounded-md bg-card-bg grid gap-3 p-5 min-h-[342px]">
          <img alt="Logo" src={GraphArrowUp} />
          <strong className="font-semibold text-lg">Proven Results</strong>
          <span className="font-light text-sm">
            With a track record of consistently increasing bankrolls by 350% or
            more, our platform demonstrates its effectiveness in generating
            substantial returns for our users. We prioritize a conservative
            approach to minimize risk while maximizing gains.
          </span>
        </div>
        <div className="text-left rounded-md bg-card-bg grid gap-3 p-5 min-h-[342px]">
          <img alt="Logo" src={AlertWifi} />
          <strong className="font-semibold text-lg">Live Alerts</strong>
          <span className="font-light text-sm">
            Stay ahead of the game with real-time alerts delivered directly to
            your device. Get instant notifications about betting opportunities,
            game-changing events, and updates on your wagers, ensuring you never
            miss out on valuable insights.
          </span>
        </div>
        <div className="text-left rounded-md bg-card-bg grid gap-3 p-5 min-h-[342px]">
          <img alt="Logo" src={MachineBrain} />
          <strong className="font-semibold text-lg">
            Machine Learning Mastery
          </strong>
          <span className="font-light text-sm">
            Our platform’s core strength lies in its machine learning
            capabilities. Over the past three years, our team of data scientists
            and engineers has tirelessly trained and refined the machine
            learning models to adapt to ever-changing NBA dynamics, increasing
            the accuracy of our predictions.
          </span>
        </div>
        <div className="text-left rounded-md bg-card-bg grid gap-3 p-5 min-h-[342px]">
          <img alt="Logo" src={AppsIcon} />
          <strong className="font-semibold text-lg">Accessible to All</strong>
          <span className="font-light text-sm">
            No longer restricted to high net worth individuals, our platform is
            now accessible to the general public. We believe that everyone
            should have the opportunity to benefit from our advanced betting
            algorithms and potentially increase their bankroll significantly.
          </span>
        </div>
        <div className="text-left rounded-md bg-card-bg grid gap-3 p-5 min-h-[342px]">
          <img alt="Logo" src={TouchedTablet} />
          <strong className="font-semibold text-lg">
            User-friendly Interface
          </strong>
          <span className="font-light text-sm">
            Our user-friendly platform ensures that even those new to NBA
            betting can easily navigate and utilize our tools. Whether you’re a
            seasoned bettor or just starting, you’ll find our platform intuitive
            and accessible.
          </span>
        </div>
      </div>
    </div>
  );
}

const Line = styled.hr`
  color: rgba(0, 0, 0, 0.65);
`;
