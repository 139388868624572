import React from 'react';
import LogoIcon from '../../static/img/logo-4@2x.png';
import { useNavigate } from 'react-router-dom';

export default function LoginSignupWrapper({ childen }) {
  const navigate = useNavigate();

  return (
    <section className="h-full w-full min-w-full min-h-screen bg-login-black-green bg-auto object-cover bg-center bg-fixed relative">
      <img
        alt="Logo"
        src={LogoIcon}
        className="relative hover:cursor-pointer pt-16 xs:left-[30%] w-32 top-[10%] lg:left-[10%] left-[6%]"
        onClick={() => navigate('/dashboard')}
      />
      {childen}
    </section>
  );
}
