import axios from 'axios';
import moment from 'moment-timezone';

export const usePosOddsAPI = () => {
  const POS_ODDS_API_URL_V1 = process.env.REACT_APP_POS_ODDS_API_URL_V1;
  const USERS_API = process.env.REACT_APP_POS_ODDS_USERS_API;
  const ODDS_API = process.env.REACT_APP_POS_ODDS_ODDS_API;
  const API_KEY = process.env.REACT_APP_API_KEY;
  // TODO: Add API

  const processGameSignal = async (
    gameId,
    enableHomeMoneylineAlertSMS,
    enableAwayMoneylineAlertSMS,
    homeMoneylineIndicator,
    awayMoneylineIndicator,
    enableHomeTrendAlertSMS,
    enableAwayTrendAlertSMS,
    homeTrendingIndicator,
    awayTrendinglineIndicator
  ) => {
    const request = {
      method: 'post',
      url: `${ODDS_API}/game/${gameId}`,
      headers: {
        accept: '*/*',
        'Content-Type': 'application/json',
      },
      data: {
        enableHomeMoneylineAlertSMS,
        enableAwayMoneylineAlertSMS,
        enableHomeTrendAlertSMS,
        enableAwayTrendAlertSMS,
        homeMoneylineIndicator,
        awayMoneylineIndicator,
        homeTrendingIndicator,
        awayTrendinglineIndicator,
      },
    };

    let res;
    res = await axios(request);

    return res;
  };

  const getGameSignal = async (gameId) => {
    const request = {
      method: 'get',
      url: `${ODDS_API}/game/${gameId}`,
      headers: {
        accept: '*/*',
        'Content-Type': 'application/json',
      },
    };

    let res;
    res = await axios(request);

    return res;
  };

  const getGamesByDate = async (date) => {
    const request = {
      method: 'get',
      url: `${POS_ODDS_API_URL_V1}/sportsdata/games-by-date/${date}`,
      headers: {
        accept: '*/*',
        'Content-Type': 'application/json',
      },
    };

    let res;

    res = await axios(request);

    return res;
  };

  const getLiveGameData = async (gameId) => {
    const request = {
      method: 'get',
      url: `${POS_ODDS_API_URL_V1}/live-odds/live-game-data/${gameId}`,
      headers: {
        accept: '*/*',
        'Content-Type': 'application/json',
      },
    };

    let res;

    res = await axios(request);

    return res;
  };

  const processBacktest = async (season, openingLine, team) => {
    const request = {
      method: 'get',
      url: `${POS_ODDS_API_URL_V1}/backtest/process-games/${season}/${openingLine}/${team}`,
      headers: {
        accept: '*/*',
        'Content-Type': 'application/json',
      },
    };

    let res;

    res = await axios(request);

    return res;
  };

  const getPregameOdds = async (gameId) => {
    const request = {
      method: 'get',
      url: `${POS_ODDS_API_URL_V1}/sportsdata/pre-game-odds/${gameId}`,
      headers: {
        accept: '*/*',
        'Content-Type': 'application/json',
      },
    };

    let res;

    res = await axios(request);

    return res;
  };

  const getInjuredPlayersByTeam = async (team) => {
    const request = {
      method: 'get',
      url: `${POS_ODDS_API_URL_V1}/live-odds/injury-by-team/${team}`,
      headers: {
        accept: '*/*',
        'Content-Type': 'application/json',
      },
    };

    let res;

    res = await axios(request);

    return res;
  };

  const getAllInjuredPlayers = async () => {
    const request = {
      method: 'get',
      url: `${POS_ODDS_API_URL_V1}/sportsdata/injured-players`,
      headers: {
        accept: '*/*',
        'Content-Type': 'application/json',
      },
    };

    let res;

    res = await axios(request);

    return res;
  };

  const getUserList = async (skip, limit) => {
    const request = {
      method: 'get',
      url: `${USERS_API}/users?skip=${skip}&limit=${limit}`,
      headers: {
        accept: '*/*',
        'Content-Type': 'application/json',
      },
    };

    let res;

    res = await axios(request);

    return res;
  };

  const createUser = async (data) => {
    const request = {
      method: 'post',
      url: `${USERS_API}/users`,
      headers: {
        accept: '*/*',
        'Content-Type': 'application/json',
      },
      data,
    };

    let res;
    res = await axios(request);

    return res;
  };

  const getAllTeamConfig = async () => {
    const request = {
      method: 'get',
      url: `${ODDS_API}/teams`,
      headers: {
        accept: '*/*',
        'Content-Type': 'application/json',
      },
    };

    let res;

    res = await axios(request);

    return res;
  };

  const getTeamConfig = async (team) => {
    const request = {
      method: 'get',
      url: `${ODDS_API}/team/${team}`,
      headers: {
        accept: '*/*',
        'Content-Type': 'application/json',
      },
    };

    let res;
    res = await axios(request);

    return res;
  };

  const setTeamConfig = async (
    team,
    enableHomeMoneylineAlertSMS,
    enableAwayMoneylineAlertSMS,
    enableHomeTrendAlertSMS,
    enableAwayTrendAlertSMS,
    requiredMovementForMoneyLineSignal,
    requiredMovementForTrendingSignal
  ) => {
    const request = {
      method: 'post',
      url: `${ODDS_API}/team/${team}`,
      headers: {
        accept: '*/*',
        'Content-Type': 'application/json',
      },
      data: {
        enableHomeMoneylineAlertSMS,
        enableAwayMoneylineAlertSMS,
        enableHomeTrendAlertSMS,
        enableAwayTrendAlertSMS,
        requiredMovementForMoneyLineSignal,
        requiredMovementForTrendingSignal,
      },
    };

    let res;
    res = await axios(request);

    return res;
  };

  const processGame = async (gameId, spread, homeTeam) => {
    const request = {
      method: 'get',
      url: `${POS_ODDS_API_URL_V1}/backtest/process-game/${gameId}/${spread}/${homeTeam}`,
      headers: {
        accept: '*/*',
        'Content-Type': 'application/json',
      },
    };

    let res;

    res = await axios(request);

    return res;
  };

  const getSeasonStats = async () => {
    const dateToday = moment().tz('America/Los_Angeles').format('YYYY-MM');

    const request = {
      method: 'get',
      url: `${ODDS_API}/admin-season-stats/${dateToday}`,
      headers: {
        'x-api-key': API_KEY,
        accept: '*/*',
        'Content-Type': 'application/json',
      },
    };

    let res;

    res = await axios(request);

    return res;
  };

  const setSeasonStats = async (data) => {
    const dateToday = moment().tz('America/Los_Angeles').format('YYYY-MM');

    const request = {
      method: 'post',
      url: `${ODDS_API}/admin-season-stats/${dateToday}`,
      headers: {
        'x-api-key': API_KEY,
        accept: '*/*',
        'Content-Type': 'application/json',
      },
      data,
    };

    let res;

    res = await axios(request);

    return res;
  };

  return {
    processGameSignal,
    getGameSignal,
    getGamesByDate,
    getLiveGameData,
    processBacktest,
    getPregameOdds,
    getInjuredPlayersByTeam,
    getAllInjuredPlayers,
    getUserList,
    createUser,
    getAllTeamConfig,
    setTeamConfig,
    processGame,
    getTeamConfig,
    getSeasonStats,
    setSeasonStats,
  };
};
