import Scorebored from '../../assets/scoreboard_img.svg';
import HeartEmoji from '../../assets/heart_emoji.svg';

export function InstaScoreboard() {
  return (
    <div className="bg-[#FFFFFF] rounded-2xl w-full flex flex-col justify-center items-start gap-3 py-7 px-4">
      <h1 className="text-[16px] text-[#2A3635] font-bold">
        Latest on our instagram
      </h1>
      <a
        href="https://www.instagram.com/positive.odds"
        target="new_blank"
        className="text-[#ED6435] text-[16px] font-bold"
      >
        @positiveodds
      </a>
      <div className="w-full flex justify-center">
        <img
          src={Scorebored}
          alt="scoreboard"
          className="rounded-lg hover:cursor-pointer"
          onClick={() =>
            window.open(
              'https://www.instagram.com/positive.odds',
              '_blank',
              'noopener,noreferrer'
            )
          }
        />
      </div>
      <img src={HeartEmoji} alt="heart_emoji" />
    </div>
  );
}
