const signupFields={
    username:
    {
        labelText:"Username",
        labelFor:"username",
        id:"username",
        name:"username",
        type:"text",
        autoComplete:"username",
        isRequired:true,
        placeholder:"Username"   
    },
    email:
    {
        labelText:"Email address",
        labelFor:"email",
        id:"email",
        name:"email",
        type:"email",
        autoComplete:"email",
        isRequired:true,
        placeholder:"Email"   
    },
    phoneNumber:
    {
        labelText:"Phone Number",
        labelFor:"phoneNumber",
        id:"phoneNumber",
        name:"phoneNumber",
        type:"text",
        autoComplete:"phoneNumber",
        isRequired:true,
        placeholder:"+1 (555) 000-0000"   
    },
    firstName:
    {
        labelText:"First name",
        labelFor:"firstName",
        id:"firstName",
        name:"firstName",
        type:"text",
        autoComplete:"firstName",
        isRequired:true,
        placeholder:"First name"   
    },
    lastName:
    {
        labelText:"Last name",
        labelFor:"lastName",
        id:"lastName",
        name:"lastName",
        type:"text",
        autoComplete:"lastName",
        isRequired:true,
        placeholder:"Last name"   
    },
    sportsbook:
    {
        labelText:"Sportsbook",
        labelFor:"sportsbook",
        id:"sportsbook",  
        name:"sportsbook",
        type:"text",
        autoComplete:"sportsbook",
        isRequired:true,
        placeholder:"Sportsbook"   
    }
}

export {signupFields}