import React, { useEffect, useState } from 'react';

const BaseButton = ({
  btnName,
  onClickHandler,
  type,
  config,
  disableSMS,
  disabled,
  className,
}) => {
  const [buttonConfig, setButtonConfig] = useState();

  return (
    <>
      {config === 'PRIMARY' && (
        <button
          type={type ? type : 'button'}
          onClick={onClickHandler}
          className={`${
            disabled
              ? 'text-main-dark-green bg-gray-400'
              : 'text-main-dark-green bg-main-green'
          } w-full py-3 rounded-md ${className}`}
          disabled={disabled ? disabled : false}
        >
          {btnName}
        </button>
      )}
      {config === 'SECONDARY' && (
        <button
          type={type ? type : 'button'}
          onClick={onClickHandler}
          className={`${
            disableSMS && 'opacity-40'
          } text-main-white bg-main-bg-black w-full py-3 rounded-md ${className}`}
          disabled={disableSMS ? disableSMS : false}
        >
          {btnName}
        </button>
      )}
    </>
  );
};

export default BaseButton;
