import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { usePosOddsAPI } from '../../hooks/usePosOddsAPI';
import LoaderModal from '../../components/Modal/LoaderModal';
import moment from 'moment-timezone';
import ErrorModal from '../../components/Modal/ErrorModal';

const Callback = () => {
  const [isCallbackLoading, setIsCallbackLoading] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const { isAuthenticated, isLoading, error, user, logout } = useAuth0();
  const navigate = useNavigate();
  const { getUserList } = usePosOddsAPI();

  const processCallbackResults = async () => {
    setIsCallbackLoading(true);
    try {
      localStorage.setItem('isLoggedIn', true);
      if (user.email === 'modular8798@test.com') {
        localStorage.setItem('role', 'admin');
        navigate('/dashboard');
      } else {
        const result = await getUserList(0, 1000);
        const users = result.data.users;
        const userByEmail = users.find(function (u) {
          return u.email === user.email;
        });

        if (userByEmail) {
          const currentDate = moment(new Date())
            .tz('America/New_York')
            .format('YYYY-MM-DD');
          const subExpiryDate = moment(
            new Date(userByEmail.subscription_expire_date)
          ).format('YYYY-MM-DD');

          if (subExpiryDate > currentDate) {
            localStorage.setItem('role', 'user');
            navigate('/dashboard');
          } else {
            navigate('/sign-up');
          }
        } else {
          navigate('/sign-up');
        }
      }
    } catch (error) {
      console.log(error);
      logout({
        logoutParams: {
          returnTo: `${window.location.origin}/admin/login`,
        },
      });
    }
  };

  if (isCallbackLoading || isLoading) {
    // TODO: Add a loader here
    return (
      <>
        <LoaderModal isVisible={isCallbackLoading} />
        <ErrorModal
          isVisible={showErrorModal}
          onCreateAccount={() => navigate('/sign-up')}
        />
      </>
    );
  }
  if (error) {
    localStorage.setItem('isLoggedIn', false);
    navigate('/admin/login');
  }

  if (isAuthenticated) {
    processCallbackResults();
  } else {
    navigate('/admin/login');
  }
};

export default Callback;
