import React from 'react';
import { useForm } from 'react-hook-form';
import LoginSignupWrapper from '../../../components/LoginSignupWrapper';
import { useAuth0 } from '@auth0/auth0-react';
import LoaderModal from '../../../components/Modal/LoaderModal';

export default function Login() {
  const { loginWithRedirect } = useAuth0();
  const [isLoading, setIsLoading] = React.useState(false);

  const {
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm({ mode: 'onBlur', shouldUseNativeValidation: false });

  const onSubmit = () => {
    setIsLoading(true);
    loginWithRedirect();
  };

  return (
    <LoginSignupWrapper
      childen={
        <>
          <form
            className="h-full w-full flex items-center justify-center px-6 lg:py-10 py-16 sm:pt-8 relative"
            onSubmit={handleSubmit(onSubmit)}
            id="login-form"
            noValidate
          >
            <div className="h-full min-h-screen mt-2 items-center justify-center w-full min:max-[640]:w-full 2xl:w-2/6 xl:w-2/5 md:w-2/3 sm:w-full xs:w-full">
              <div className="text-main-white text-3xl sm:text-2xl lg:text-[64px] font-semibold pb-[38px]">
                Login
              </div>
              <button
                form="login-form"
                className="bg-main-green rounded-md justify-center items-center text-gray-800 text-[19px] font-semibold w-full mt-8 p-4"
                type="onSubmit"
                // onClick={handleSubmit(onSubmit)}
              >
                Login
              </button>
              {/* <p className="text-right py-2 text-main-white text-sm font-normal font-['Inter'] leading-tight hover:cursor-pointer">
                Forgot your password?
              </p>
              <p className="text-left text-main-white text-base font-normal mt-12">
                Don't have an account yet?
                <u
                  className="hover:cursor-pointer text-main-green pl-1"
                  onClick={() => {
                    loginWithRedirect({
                      authorizationParams: {
                        screen_hint: 'signup',
                      },
                    });
                  }}
                >
                  Sign up now
                </u>
              </p> */}
            </div>
          </form>
          <LoaderModal isVisible={isLoading} />
          {process.env.NODE_ENV === 'development' ? (
            <pre>{JSON.stringify(watch(), null, 2)}</pre>
          ) : null}
        </>
      }
    />
  );
}
