import React, { useState, useEffect } from 'react';
import FullLiveGameCard from '../../components/GameCard/FullLiveGameCard';
import BlueEllipse from '../../static/img/blue-ellipse.png';
import SetGameSignalModal from '../../components/Modal/SetGameSignalModal';
import { usePosOddsAPI } from '../../hooks/usePosOddsAPI';
import SuccessSetGameSignalModal from '../../components/Modal/SuccessSetGameSignalModal';
import { TailSpin } from 'react-loader-spinner';
import moment from 'moment-timezone';
import _ from 'lodash';

// TODO: Add disabling of game signals
const LiveGames = () => {
  const [showSignalModal, setShowSignalModal] = useState(false);
  const [selectedGame, setSelectedGame] = useState({
    awayTeam: 'Away',
    homeTeam: 'Home',
  });
  const [liveGames, setLiveGames] = useState([]);
  const [showSuccessSetSignalModal, setShowSuccessSetSignalModal] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [teamConfig, setTeamConfig] = useState({});

  const { getGamesByDate, getAllTeamConfig } = usePosOddsAPI();

  const handleGetAllTeamConfig = async () => {
    try {
      const res = await getAllTeamConfig();
      const teamConfigData = res.data;

      setTeamConfig(teamConfigData);
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetLiveGames = async () => {
    let gamesToday = [];
    const estDateToday = moment().tz('America/New_York').format('YYYY-MM-DD');

    try {
      const res = await getGamesByDate(estDateToday);

      if (res.data.data.length === 0) setLiveGames([]);
      const onGoingGames = res.data.data.filter(
        (game) => game.Status === 'InProgress'
      );

      for (let i = 0; i < onGoingGames.length; i++) {
        gamesToday.push(onGoingGames[i]);
      }
      setLiveGames(gamesToday);
    } catch (error) {
      console.log(error);
      setLiveGames([]);
    }
  };

  useEffect(() => {
    handleGetLiveGames();

    const intervalId = setInterval(handleGetLiveGames, 6000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    handleGetAllTeamConfig();
  }, []);

  return (
    <div className="h-full w-full text-main-white">
      <div></div>
      <div className="flex text-xl py-6">
        <div>Tonight</div>
        <div className="flex items-center mx-2">
          <img src={BlueEllipse} />
        </div>
        <div className=" text-main-gray-text">
          {moment().tz('America/New_York').format('MMMM DD, dddd')}
        </div>
      </div>

      <div
        className={`grid grid-flow-col auto-cols-max gap-5 pb-10 lg:px-2 px-4 lg:max-w-[75vw] max-w-[100vw] overflow-y-hidden snap-proximity snap-x 
        ${liveGames.length > 0 ? ' overflow-x-scroll' : ' overflow-x-hidden'}`}
      >
        {/* TODO: Update mapping on actual game date */}
        {isLoading ? (
          <div className="flex h-full w-full flex-col justify-center items-center">
            <TailSpin
              height="120"
              width="120"
              color="#5AC561"
              ariaLabel="tail-spin-loading"
              radius="0"
              wrapperStyle={{}}
              wrapperClass=""
              visible={isLoading}
            />
          </div>
        ) : liveGames.length > 0 && !_.isEmpty(teamConfig) ? (
          liveGames.map((game, id) => {
            return (
              <FullLiveGameCard
                key={id}
                game={game}
                setShowSignalModal={setShowSignalModal}
                setSelectedGame={setSelectedGame}
                teamConfig={teamConfig}
              />
            );
          })
        ) : (
          <div>No Games in Progress</div>
        )}
      </div>
      <SetGameSignalModal
        isVisible={showSignalModal}
        onClose={() => setShowSignalModal(false)}
        selectedGame={selectedGame}
      />

      <SuccessSetGameSignalModal
        isVisible={showSuccessSetSignalModal}
        onClose={() => setShowSuccessSetSignalModal(false)}
        selectedGame={selectedGame}
        setShowSuccessSetSignalModal={setShowSuccessSetSignalModal}
      />
    </div>
  );
};

export default LiveGames;
