import React from 'react';

const BaseCard = ({ children }) => {
  return (
    <div className="w-full bg-card-bg flex flex-col items-center p-8 rounded-xl">
      {children}
    </div>
  );
};

export default BaseCard;
