import axios from 'axios';

export const useSportsDataAPI = () => {
  const SPORTSDATA_URL = 'https://api.sportsdata.io/v3/nba';

  const getActiveNBATeams = async () => {
    const request = {
      method: 'get',
      url: `https://api.sportsdata.io/v3/nba/scores/json/teams?key=${process.env.REACT_APP_SPORTSDATA_SUB_KEY_1}`,
      headers: {
        accept: '*/*',
        'Content-Type': 'application/json',
      },
    };

    let res;
    res = await axios(request);
    console.log(res);
    return res;
  };

  const getGamesSchedule = async (season) => {
    const request = {
      method: 'get',
      url: `https://api.sportsdata.io/v3/nba/scores/json/Games/season=${season}`,
      headers: {
        'Ocp-Apim-Subscription-Key': process.env.REACT_APP_SPORTSDATA_SUB_KEY_2,
      },
    };

    let response;

    try {
      response = await axios(request);
    } catch (error) {
      console.log(error);
      throw new Error(error);
    }
  };

  const getOdds = async (id) => {
    const config = {
      method: 'GET',
      url: `https://api.sportsdata.io/v3/nba/odds/json/LiveGameOddsLineMovement/${id}`,
      headers: {
        'Ocp-Apim-Subscription-Key': process.env.REACT_APP_SPORTSDATA_SUB_KEY_1,
      },
    };

    let response;

    try {
      response = await axios(config);
    } catch (error) {
      console.log(error);
      throw new Error(error);
    }

    return response;
  };

  const getPregameOdds = async (id) => {
    const config = {
      method: 'GET',
      url: `https://api.sportsdata.io/v3/nba/odds/json/GameOddsLineMovement/${id}`,
      headers: {
        'Ocp-Apim-Subscription-Key': process.env.REACT_APP_SPORTSDATA_SUB_KEY_1,
      },
    };

    let response;

    try {
      response = await axios(config);
    } catch (error) {
      console.log(error);
      throw new Error(error);
    }

    return response;
  };

  const getLiveGameOddsByDate = async (date) => {
    const config = {
      method: 'GET',
      url: `https://api.sportsdata.io/v3/nba/odds/json/LiveGameOddsByDate/${date}`,
      headers: {
        'Ocp-Apim-Subscription-Key': process.env.REACT_APP_SPORTSDATA_SUB_KEY_1,
      },
    };

    let response;
    try {
      response = await axios(config);
    } catch (error) {
      console.log(error);
      throw new Error(error);
    }

    return response.data;
  };

  return {
    getActiveNBATeams,
    getGamesSchedule,
    getOdds,
    getPregameOdds,
    getLiveGameOddsByDate,
  };
};
