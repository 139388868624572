import InjuredPlayersCard from './InjuredPlayersCard';
import PosPerfCard from './PosPerfCard';
import ScheduledGameCard from '../../components/GameCard/PublicGameCards/ScheduledGameCard';
import moment from 'moment';
import TodayGameCard from './TodayGameCard';

export default function PublicDashboard({
  cardData,
  todayGames,
  schedGames,
  injuredData,
}) {
  const ScheduledGames = (schedGames) => {
    return schedGames.length > 0 ? (
      schedGames?.map((game, id) => {
        return (
          <div key={id}>
            <ScheduledGameCard key={game.GameID} game={game} />
          </div>
        );
      })
    ) : (
      <div className="w-full text-white text-center my-10">
        No Scheduled Games
      </div>
    );
  };

  return (
    <div className="px-8 grid gap-12 w-full">
      <PosPerfCard data={cardData} />
      <div className="grid grid-col-7 sm:grid-col-12 lg:grid-col-3 lg:grid-flow-col lg:gap-6 md:gap-6 gap-12">
        <div className="col-span-7 sm:col-span-12 lg:col-span-1 flex flex-col lg:gap-6 md:gap-6 gap-12 items-start">
          <InjuredPlayersCard data={injuredData} />
          <div className="w-full rounded-2xl bg-[#28473e] p-2">
            <span className="w-full flex justify-center text-sm font-normal text-tana-main pt-2">
              {moment().format('MMMM DD')}
            </span>
            <div className="grid items-start w-full">
              {ScheduledGames(schedGames)}
            </div>
          </div>
        </div>
        <div className="grid grid-col-12 col-span-7 sm:col-span-12 mt-[2px] mb-[-12px]">
          <TodayGameCard data={todayGames} />
        </div>
      </div>
    </div>
  );
}
