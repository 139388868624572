import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SideBar from '../../components/SideBar';
import BaseCard from '../../components/Card';
import { AiFillPlusCircle, AiOutlineClose } from 'react-icons/ai';
import { IoSendSharp } from 'react-icons/io5';

const AdminSMS = () => {
  const navigate = useNavigate();
  const [customReceiverList, setCustomReceiverList] = useState([]);
  const [customReceiverInput, setCustomReceiverInput] = useState('');

  useEffect(() => {
    const role = localStorage.getItem('role');

    if (role !== 'admin') {
      navigate('/dashboard');
    }
  }, []);

  const handleAddCustomReceiver = () => {
    setCustomReceiverList([...customReceiverList, customReceiverInput]);
    setCustomReceiverInput('');
  };

  const handleRemoveNumber = (toBeRemoved) => {
    const newReceiverList = customReceiverList.filter(
      (number) => number !== toBeRemoved
    );
    setCustomReceiverList(newReceiverList); // Assuming your state updating function is setCustomReceiverList
  };

  const handleSendSMS = async () => {};
  return (
    <div className="w-full h-screen bg-main-bg-gray text-main-white font-inter flex">
      <SideBar />

      {/* Top Section */}
      <div className="w-full">
        <div className="flex justify-between">
          <div className="text-2xl py-4 px-16 font-bold ">Send an SMS</div>
        </div>
        <div className="pl-16 pr-6 mt-12 flex w-full">
          <div className="w-1/2">
            <BaseCard>
              <div className="w-full">
                <div>Select Game</div>
                <select className="bg-secondary-bg-gray px-5 py-4 text-gray-400 mt-2 rounded border-[#424545] border w-full">
                  <option>SAC vs GSW - April 23, 9:00PM Sunday</option>
                </select>
              </div>

              <div className="w-full mt-6">
                <div>Send Group SMS To</div>
                <select className="bg-secondary-bg-gray px-5 py-4 text-gray-400 mt-2 rounded border-[#424545] border w-full">
                  <option>All Premium subscribers</option>
                </select>
              </div>

              <div className="w-full mt-6">
                <div>Send Individual SMS To</div>

                <div className="flex items-center">
                  <input
                    className="bg-secondary-bg-gray px-5 py-4 text-gray-400 mt-2 rounded border-[#424545] border w-full"
                    placeholder="Receiver Number"
                    value={customReceiverInput}
                    onChange={(e) => {
                      setCustomReceiverInput(e.target.value);
                    }}
                  />

                  <AiFillPlusCircle
                    className="text-main-green cursor-pointer ml-3 w-[24px] h-[24px]"
                    onClick={handleAddCustomReceiver}
                  />
                </div>

                <div className="mt-4 ml-5">
                  {customReceiverList.length > 0 &&
                    customReceiverList.map((number, key) => {
                      return (
                        <div
                          key={key}
                          className="text-[#ECECEC] text-sm flex justify-between"
                        >
                          <div>{number}</div>
                          <AiOutlineClose
                            className="cursor-pointer"
                            onClick={() => handleRemoveNumber(number)}
                          />
                        </div>
                      );
                    })}
                </div>
              </div>
            </BaseCard>
          </div>
          <div className="w-1/2 ml-4">
            <BaseCard>
              <div className="w-full">
                <div>Message</div>
                <textarea className="bg-secondary-bg-gray mt-2 px-5 py-4 w-full border border-[#424545] rounded outline-none"></textarea>
              </div>

              <div className="flex justify-between items-center w-full mt-8">
                <div className="text-[#47B7CB] font-semibold text-xs">
                  Send a test
                </div>
                <button
                  type="button"
                  onClick={handleSendSMS}
                  className={`text-main-dark-green bg-main-green px-4 py-3 rounded-md flex`}
                >
                  Send
                  <IoSendSharp className="ml-2" />
                </button>
              </div>
            </BaseCard>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminSMS;
