import React, { useEffect, useState } from 'react';
import * as NBAIcons from 'react-nba-logos';
import LiveIcon from '../../static/img/live_icon.png';
import BaseButton from '../Buttons/BaseButton';
import { parseTeamName, parseQuarterAndTime } from '../../utils';
import { usePosOddsAPI } from '../../hooks/usePosOddsAPI';

const FullGameCard = ({
  game,
  setShowSignalModal,
  setSelectedGame,
  teamConfig,
}) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [liveGameDetails, setLiveGameDetails] = useState({});
  const [homeScore, setHomeScore] = useState(0);
  const [awayScore, setAwayScore] = useState(0);
  const [animateHomeScore, setAnimateHomeScore] = useState(false);
  const [animateAwayScore, setAnimateAwayScore] = useState(false);

  const HomeIcon = NBAIcons[parseTeamName(game.HomeTeam)];
  const AwayIcon = NBAIcons[parseTeamName(game.AwayTeam)];

  const { getLiveGameData, getGameSignal } = usePosOddsAPI();

  const getGameConfig = async () => {
    const { GameID, AwayTeam, HomeTeam } = game;

    try {
      const gameSignal = await getGameSignal(GameID);

      if (
        gameSignal.data.awayMoneylineIndicator !== null ||
        gameSignal.data.homeMoneylineIndicator !== null
      ) {
        return gameSignal.data;
      } else {
        const homeConfig = teamConfig[parseTeamName(HomeTeam)];
        const awayConfig = teamConfig[parseTeamName(AwayTeam)];

        return {
          enableHomeMoneylineAlertSMS: homeConfig.enableHomeMoneylineAlertSMS,
          enableAwayMoneylineAlertSMS: awayConfig.enableHomeMoneylineAlertSMS,
          enableHomeTrendAlertSMS: homeConfig.enableHomeTrendAlertSMS,
          enableAwayTrendAlertSMS: awayConfig.enableAwayTrendAlertSMS,
          homeMoneylineIndicator: homeConfig.requiredMovementForMoneyLineSignal,
          awayMoneylineIndicator: awayConfig.requiredMovementForMoneyLineSignal,
          homeTrendingIndicator: homeConfig.requiredMovementForTrendingSignal,
          awayTrendinglineIndicator:
            awayConfig.requiredMovementForTrendingSignal,
        };
      }
    } catch (error) {
      try {
        const homeConfig = teamConfig[parseTeamName(HomeTeam)];
        const awayConfig = teamConfig[parseTeamName(AwayTeam)];

        return {
          enableHomeMoneylineAlertSMS: homeConfig.enableHomeMoneylineAlertSMS,
          enableAwayMoneylineAlertSMS: awayConfig.enableHomeMoneylineAlertSMS,
          enableHomeTrendAlertSMS: homeConfig.enableHomeTrendAlertSMS,
          enableAwayTrendAlertSMS: awayConfig.enableAwayTrendAlertSMS,
          homeMoneylineIndicator: homeConfig.requiredMovementForMoneyLineSignal,
          awayMoneylineIndicator: awayConfig.requiredMovementForMoneyLineSignal,
          homeTrendingIndicator: homeConfig.requiredMovementForTrendingSignal,
          awayTrendinglineIndicator:
            awayConfig.requiredMovementForTrendingSignal,
        };
      } catch (error) {
        const homeConfig = teamConfig[HomeTeam];
        const awayConfig = teamConfig[AwayTeam];

        return {
          enableHomeMoneylineAlertSMS: homeConfig.enableHomeMoneylineAlertSMS,
          enableAwayMoneylineAlertSMS: awayConfig.enableHomeMoneylineAlertSMS,
          enableHomeTrendAlertSMS: homeConfig.enableHomeTrendAlertSMS,
          enableAwayTrendAlertSMS: awayConfig.enableAwayTrendAlertSMS,
          homeMoneylineIndicator: homeConfig.requiredMovementForMoneyLineSignal,
          awayMoneylineIndicator: awayConfig.requiredMovementForMoneyLineSignal,
          homeTrendingIndicator: homeConfig.requiredMovementForTrendingSignal,
          awayTrendinglineIndicator:
            awayConfig.requiredMovementForTrendingSignal,
        };
      }
    }
  };

  const handleGetLiveGame = async (game) => {
    const liveGame = await getLiveGameData(game.GameID);

    const liveGameData = liveGame.data.data;
    if (isAdmin) {
      const gameSignal = await getGameConfig();
      const currentLiveGame = {
        ...game,
        liveOdds: liveGameData.liveOdds,
        gameSignal,
      };
      console.log('currentLiveGame', currentLiveGame);

      setLiveGameDetails(currentLiveGame);
    } else {
      const currentLiveGame = {
        ...game,
        liveOdds: liveGameData.liveOdds,
      };
      console.log('currentLiveGame', currentLiveGame);

      setLiveGameDetails(currentLiveGame);
    }
  };

  useEffect(() => {
    const role = localStorage.getItem('role');

    if (role === 'admin') {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, []);

  useEffect(() => {
    handleGetLiveGame(game);
  }, [game]);

  useEffect(() => {
    if (homeScore < game.HomeTeamScore) {
      setHomeScore(game.HomeTeamScore);
      setAnimateHomeScore(true);
      setTimeout(() => setAnimateHomeScore(false), 2500);
    }
  }, [game.HomeTeamScore]);

  useEffect(() => {
    if (awayScore < game.AwayTeamScore) {
      setAwayScore(game.AwayTeamScore);
      setAnimateAwayScore(true);
      setTimeout(() => setAnimateAwayScore(false), 2500);
    }
  }, [game.AwayTeamScore]);

  return (
    <div className="bg-card-bg flex flex-col items-center p-8 rounded-xl text-main-white font-inter w-full snap-center">
      <div>
        <div></div>
        <div>
          <div className="flex items-center">
            <div>
              <AwayIcon />
            </div>
            <div
              className={`text-xl ${
                animateHomeScore || animateAwayScore ? 'animate-blink-slow' : ''
              }`}
            >
              {liveGameDetails.AwayTeamScore} - {liveGameDetails.HomeTeamScore}
            </div>
            <div>
              <HomeIcon />
            </div>
          </div>
          <div className="flex items-center justify-center">
            <div className="flex flex-col items-center">
              <div className="font-semibold text-lg">
                {liveGameDetails.AwayTeamName}
              </div>
              <div className=" font-light text-xs">Away</div>
            </div>
            <div className="px-11">
              <img src={LiveIcon} className="w-16 animate-blink-slow" />
            </div>
            <div className="flex flex-col items-center">
              <div className="font-semibold text-lg">
                {liveGameDetails.HomeTeamName}
              </div>
              <div className=" font-light text-xs">Home</div>
            </div>
          </div>

          <div className="flex items-center justify-center mt-4 text-main-green">
            {parseQuarterAndTime(liveGameDetails)}
          </div>
        </div>
      </div>

      {liveGameDetails.liveOdds && (
        <div className="flex flex-col items-center mt-4 w-full">
          <div className="my-2">Current</div>
          <div className="bg-secondary-bg-gray rounded-md flex flex-col justify-between p-4 font-semibold text-base w-full gap-5">
            <div className="flex justify-around">
              <div>
                {liveGameDetails.liveOdds[0].AwayPointSpread
                  ? liveGameDetails.liveOdds[0].AwayPointSpread
                  : 0}
              </div>
              <div className="font-light">Live spread</div>
              <div>
                {liveGameDetails.liveOdds[0].HomePointSpread
                  ? liveGameDetails.liveOdds[0].HomePointSpread
                  : 0}
              </div>
            </div>
            <div className="flex justify-around mt-4">
              <div>{liveGameDetails.liveOdds[0].AwayMoneyLine}</div>
              <div className="font-light">Money line</div>
              <div>{liveGameDetails.liveOdds[0].HomeMoneyLine}</div>
            </div>

            {/* <div className="flex justify-around">
            <div>{liveGameDetails.liveOdds.AwayMoneyLine}</div>
            <div>Live line</div>
            <div>{liveGameDetails.liveOdds.HomeMoneyLine}</div>
          </div> */}
          </div>
        </div>
      )}

      <div className="flex flex-col items-center mt-4 w-full">
        <div className="my-2">Opening</div>
        <div className="bg-secondary-bg-gray rounded-md flex flex-col justify-between p-4 font-semibold text-base w-full gap-5">
          <div className="flex justify-around">
            <div>
              {liveGameDetails.PointSpread
                ? liveGameDetails.PointSpread * -1
                : 0}
            </div>
            <div className="font-light">Open spread</div>
            <div>
              {liveGameDetails.PointSpread ? liveGameDetails.PointSpread : 0}
            </div>
          </div>
          <div className="flex justify-around mt-4">
            <div>{liveGameDetails.AwayTeamMoneyLine}</div>
            <div className="font-light">Money line</div>
            <div>{liveGameDetails.HomeTeamMoneyLine}</div>
          </div>
          {/* <div className="flex justify-around">
            <div>{0}</div>
            <div>Live line</div>
            <div>{0}</div>
          </div>
          <div className="flex justify-around">
            <div>{0}</div>
            <div>Injuries</div>
            <div>{0}</div>
          </div> */}
        </div>
      </div>

      {isAdmin && (
        <div className="mt-4 w-full px-10">
          <BaseButton
            btnName={'Set Game Signals'}
            config={'PRIMARY'}
            onClickHandler={() => {
              setSelectedGame({
                gameId: liveGameDetails.GameId,
                homeTeam: liveGameDetails.HomeTeam,
                awayTeam: liveGameDetails.AwayTeam,
                gameSignal: liveGameDetails.gameSignal,
              });
              setShowSignalModal(true);
            }}
          />
          <BaseButton
            btnName={'Disable Game Signal'}
            config={'SECONDARY'}
            onClickHandler={() => {
              setSelectedGame({
                gameId: liveGameDetails.GameId,
                homeTeam: liveGameDetails.HomeTeam,
                awayTeam: liveGameDetails.AwayTeam,
                gameSignal: liveGameDetails.gameSignal,
              });
              setShowSignalModal(true);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default FullGameCard;
