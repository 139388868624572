import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ProtectedRoute = ({ element, onlyAdmin }) => {
  const navigate = useNavigate();
  const isLoggedIn = localStorage.getItem('isLoggedIn');
  const role = localStorage.getItem('role');

  useEffect(() => {
    if (isLoggedIn === 'false') {
      navigate('/admin/login');
    } else {
      if (role !== 'admin' && onlyAdmin) {
        navigate('/dashboard');
      }
    }
  }, [isLoggedIn, onlyAdmin, role]);

  return <>{element}</>;
};

export default ProtectedRoute;
