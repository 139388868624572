import { Card, CardBody, CardHeader } from '@material-tailwind/react';
import Dunk1 from '../../assets/backgrounds/dunk-1-bg.svg';
import { isNumber } from 'lodash';

export default function PosPerfCard(props) {
  const { data } = props;

  return (
    <div>
      <text className="text-lg font-semibold text-main-white mb-0.5">
        Positive Performance
      </text>
      <div className="grid lg:grid-col-3 lg:grid-flow-col w-full gap-6">
        {data &&
          data.map((item, i) => (
            <div key={i} className="relative grid max-w-[100%] h-{100%} pt-4">
              <Card
                shadow={false}
                className="relative grid h-{90%} w-full items-end justify-start overflow-hidden text-left"
              >
                <CardHeader
                  floated={false}
                  shadow={false}
                  color="transparent"
                  className="absolute inset-0 m-0 h-full w-full rounded-none bg-gradient-to-br from-[#CBF1CD] to-[#97DA9B] bg-cover bg-center"
                ></CardHeader>
                <CardBody className="relative grid gap-4 py-14 p-3 text-main-bg-black w-full">
                  <text className="text-4xl font-semibold">
                    {item.value && isNumber(item.value)
                      ? item.value
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      : item.value}
                  </text>
                  <text className="text-xs">{item.name}</text>
                </CardBody>
              </Card>
              <img
                alt="dunk-1"
                src={item.bg}
                className="absolute top-0 right-4 p-0 h-full"
              />
            </div>
          ))}
      </div>
    </div>
  );
}
