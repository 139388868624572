import React, { useEffect, useState } from 'react';
import BaseButton from '../Buttons/BaseButton';
import InputField from '../InputField/InputField';
import { useForm, Controller } from 'react-hook-form';
import { IconContext } from 'react-icons';
import { IoMdClose } from 'react-icons/io';
import { usePosOddsAPI } from '../../hooks/usePosOddsAPI';
import { TailSpin } from 'react-loader-spinner';
import { Switch } from '@material-tailwind/react';

const UpdateTeamConfigModal = ({
  isVisible,
  selectedTeamConfig,
  onClose,
  setShowSuccessTeamConfigModal,
  onSuccess,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [enableMoneylineAlerts, setEnableMoneylineAlerts] = useState(
    selectedTeamConfig.enableHomeMoneylineAlertSMS
  );
  const [enableTrendAlertSMS, setEnableTrendAlertSMS] = useState(
    selectedTeamConfig.enableHomeTrendAlertSMS
  );

  const { setTeamConfig } = usePosOddsAPI();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,

    control,
  } = useForm({ mode: 'all' });

  const onSubmit = async (data) => {
    setIsLoading(true);

    try {
      const res = await setTeamConfig(
        data.teamId,
        getValues('enableHomeMoneylineAlertSMS'),
        getValues('enableAwayMoneylineAlertSMS'),
        getValues('enableHomeTrendAlertSMS'),
        getValues('enableAwayTrendAlertSMS'),
        getValues('requiredMovementForMoneyLineSignal'),
        getValues('requiredMovementForTrendingSignal')
      );
      if (res.status === 200) {
        setIsLoading(false);
        onSuccess();
        onClose();
        setShowSuccessTeamConfigModal(true);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const onMoneylineSmsAlertChange = (e) => {
    setEnableMoneylineAlerts(e.target.checked);
    setValue('enableAwayMoneylineAlertSMS', e.target.checked);
    setValue('enableHomeMoneylineAlertSMS', e.target.checked);
  };

  const onTrendingSmsAlertChange = (e) => {
    setEnableTrendAlertSMS(e.target.checked);
    setValue('enableAwayTrendAlertSMS', e.target.checked);
    setValue('enableHomeTrendAlertSMS', e.target.checked);
  };

  React.useEffect(() => {
    if (selectedTeamConfig && selectedTeamConfig.length);
    {
      setValue(
        'enableAwayMoneylineAlertSMS',
        selectedTeamConfig.enableAwayMoneylineAlertSMS
      );
      setValue(
        'enableHomeMoneylineAlertSMS',
        selectedTeamConfig.enableHomeMoneylineAlertSMS
      );
      setValue(
        'enableAwayTrendAlertSMS',
        selectedTeamConfig.enableAwayTrendAlertSMS
      );
      setValue(
        'enableHomeTrendAlertSMS',
        selectedTeamConfig.enableHomeTrendAlertSMS
      );
      setEnableMoneylineAlerts(selectedTeamConfig.enableHomeMoneylineAlertSMS);
      setEnableTrendAlertSMS(selectedTeamConfig.enableHomeTrendAlertSMS);
      setValue('teamName', selectedTeamConfig.name);
      setValue(
        'requiredMovementForTrendingSignal',
        selectedTeamConfig.requiredMovementForTrendingSignal
      );
      setValue(
        'requiredMovementForMoneyLineSignal',
        selectedTeamConfig.requiredMovementForMoneyLineSignal
      );
      setValue('teamId', selectedTeamConfig.teamAbbr);
    }
  }, [isVisible, selectedTeamConfig]);

  return (
    <div className="flex justify-center">
      {isVisible && (
        <div
          className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-30 z-10"
          style={{ backdropFilter: 'blur(5px)' }}
        />
      )}
      {isVisible && (
        <div className="fixed top-0 left-0 w-full h-full z-20 flex items-center justify-center text-main-white">
          <div className="w-3/4 lg:w-2/6 bg-main-bg-gray rounded-xl border p-10">
            <div
              className="modal-content rounded-card"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="flex w-full justify-end text-xl">
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    onClose();
                  }}
                >
                  <IconContext.Provider value={{ color: 'white' }}>
                    <IoMdClose />
                  </IconContext.Provider>
                </div>
              </div>

              <div className="flex flex-col text-main-white text-lg">
                <form onSubmit={handleSubmit(onSubmit)}>
                  {!isLoading && <div>{/* {away} vs {home} */}</div>}

                  {isLoading && (
                    <>
                      Processing...
                      <div className="flex justify-center">
                        <TailSpin
                          height="120"
                          width="120"
                          color="#5AC561"
                          ariaLabel="tail-spin-loading"
                          radius="0"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={isLoading}
                        />
                      </div>
                    </>
                  )}
                  {/* NOTE: Section to show when enabling game signal */}
                  {!isLoading && (
                    <>
                      <div className="text-lg flex justify-start items-center pb-4 font-normal">
                        {selectedTeamConfig.teamIcon}
                        {selectedTeamConfig.name}
                      </div>
                      <div className="text-2xl pb-2">Update Team Config</div>

                      <div className="flex mt-4">
                        <div></div>

                        <div className="w-full">
                          <div className="text-xs font-light">
                            Signal alert indicator
                          </div>
                          {/* TODO: Create validation for indicator input */}
                          <InputField
                            type={'text'}
                            registerField={register}
                            fieldId={'requiredMovementForMoneyLineSignal'}
                            required={true}
                            validate={{}}
                            defaultValue={0}
                            // value={getValues('signalIndicator')}
                            placeholder={'0000'}
                          />
                        </div>
                        <div className="w-full">
                          <div className="text-xs font-light">
                            Trending alert indicator
                          </div>
                          {/* TODO: Create validation for indicator input */}
                          <InputField
                            type={'text'}
                            registerField={register}
                            fieldId={'requiredMovementForTrendingSignal'}
                            required={true}
                            validate={{}}
                            defaultValue={0}
                            // value={getValues('signalIndicator')}
                            placeholder={'0000'}
                          />
                        </div>
                      </div>

                      <div className="flex justify-between mt-10 w-full">
                        <p className="text-xs font-light">
                          In the money SMS alerts
                        </p>
                        <div>
                          <Controller
                            name={'enableHomeMoneylineAlertSMS'}
                            control={control}
                            render={({ field }) => (
                              <Switch
                                {...field}
                                id="enableHomeMoneylineAlertSMS"
                                label={enableMoneylineAlerts ? 'On' : 'Off'}
                                ripple={true}
                                checked={enableMoneylineAlerts}
                                onChange={onMoneylineSmsAlertChange}
                                className="h-full w-full checked:bg-main-green"
                              />
                            )}
                          />
                        </div>
                      </div>

                      <div className="flex justify-between mt-2 w-full">
                        <p className="text-xs font-light">
                          Trending SMS alerts
                        </p>
                        <div>
                          <Controller
                            name={'enableHomeTrendAlertSMS'}
                            control={control}
                            render={({ field }) => (
                              <Switch
                                {...field}
                                id="enableHomeTrendAlertSMS"
                                label={enableTrendAlertSMS ? 'On' : 'Off'}
                                ripple={true}
                                checked={enableTrendAlertSMS}
                                onChange={onTrendingSmsAlertChange}
                                className="h-full w-full checked:bg-main-green"
                              />
                            )}
                          />
                        </div>
                      </div>

                      <div className="flex flex-col items-center w-full font-normal mt-4">
                        <BaseButton
                          config={'PRIMARY'}
                          type={'submit'}
                          btnName={'Save team config'}
                          onClickHandler={() => {
                            handleSubmit(onSubmit);
                          }}
                        />
                      </div>
                    </>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UpdateTeamConfigModal;
