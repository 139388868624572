import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const navigate = useNavigate();
  const isLoggedIn = localStorage.getItem('isLoggedIn');

  useEffect(() => {
    if (isLoggedIn === 'false') {
      navigate('/admin/login');
    } else {
      navigate('/dashboard');
    }
  }, [isLoggedIn]);
  return <div>NotFound</div>;
};

export default NotFound;
