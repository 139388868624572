import NavBar from '../../components/NavBar';
import SideBar from '../../components/SideBar';

export default function MainWrapper({ element }) {
  return (
    <div className="w-full h-{100%}">
      <div className="lg:flex block w-full h-full">
        <div className="w-full lg:w-[20%] h-{100%}">
          <div className="hidden lg:block h-full">
            <SideBar />
          </div>
          <div className="hidde sm:block">
            <NavBar />
          </div>
        </div>
        <div className="lg:w-[80%] h-{100%} pb-12">{element}</div>
      </div>
    </div>
  );
}
