import React from 'react';

const InputField = ({
  type,
  registerField,
  fieldId,
  required,
  validate,
  defaultValue,
  placeholder,
  disabled,
  className,
}) => {
  return (
    <input
      {...registerField(`${fieldId}`, {
        required: required,
        validate: validate,
      })}
      type={type}
      defaultValue={defaultValue ? defaultValue : ''}
      className={`border-gray-400 bg-main-dark-green mt-3 lg:mt-1 focus:outline-none rounded-3xl lg:rounded leading-5 lg:text-sm py-8 pl-8 lg:py-4 lg:pl-5 w-full font-light text-tertiary-gray ${className}`}
      placeholder={placeholder ? placeholder : ''}
      disabled={disabled ? disabled : false}
    />
  );
};

export default InputField;
