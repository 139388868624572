import React from 'react';
import BaseButton from '../Buttons/BaseButton';
import { IconContext } from 'react-icons';
import { BiErrorCircle } from 'react-icons/bi';

const ErrorModal = ({ isVisible, onCreateAccount }) => {
  return (
    <div className="flex justify-center">
      {isVisible && (
        <div
          className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-30 z-10"
          style={{ backdropFilter: 'blur(5px)' }}
        />
      )}
      {isVisible && (
        <div className="fixed top-0 left-0 w-full h-full z-20 flex items-center justify-center text-main-white">
          <div className="w-3/4 lg:w-2/5 bg-main-bg-gray rounded-xl border p-10">
            <div
              className="modal-content rounded-card"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="flex flex-col items-center text-main-white">
                <div className="cursor-pointer text-4xl">
                  <IconContext.Provider value={{ color: 'red' }}>
                    <BiErrorCircle />
                  </IconContext.Provider>
                </div>
                <div className="text-2xl flex justify-center my-2">
                  You are not subscribed
                </div>

                <div className="flex justify-center py-10 w-full">
                  <div className="flex flex-col items-center w-full font-normal">
                    <BaseButton
                      config={'PRIMARY'}
                      type={'submit'}
                      btnName={'Create Account'}
                      onClickHandler={() => {
                        onCreateAccount();
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ErrorModal;
