import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { usePosOddsAPI } from '../../hooks/usePosOddsAPI';
import SearchBar from '../../components/SearchBar';
import Dunk1 from '../../assets/backgrounds/dunk-1-bg.svg';
import Dunk2 from '../../assets/backgrounds/dunk-2-bg.svg';
import Dunk3 from '../../assets/backgrounds/dunk-3-bg.svg';
import AdminDashboard from './AdminDashboard';
import PublicDashboard from './PublicDashboard';

// TODO: Fetch actual injured player data
const injuredData = [
  // {
  //   name: 'Kyrie Irving',
  //   teamCode: 'BKN',
  // },
  // {
  //   name: 'Lebron James',
  //   teamCode: 'LAL',
  // },
  // {
  //   name: 'Kawhi Lennard',
  //   teamCode: 'LAC',
  // },
];

const signalData = [
  {
    name: 'Story',
    value: 120,
  },
  {
    name: 'Tweeted',
    value: 102,
  },
  {
    name: 'Posted',
    value: 76,
  },
  {
    name: 'Reels',
    value: 201,
  },
  {
    name: 'Covered',
    value: 98,
  },
];

const statsData = [
  {
    statName: 'Signal stat',
    amount: 1000,
    percentage: 89,
  },
  {
    statName: 'Signal stat',
    amount: 3200,
    percentage: 74,
  },
];

export default function Dashboard() {
  const userRole = localStorage.getItem('role');
  const [liveGames, setLiveGames] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [schedGames, setSchedGames] = useState([]);
  const [todayGames, setTodayGames] = useState([]);
  const [seasonStats, setSeasonStats] = useState([
    {
      name: 'Plays',
      value: 0,
      bg: Dunk1,
    },
    {
      name: 'Lines watched',
      value: 0,
      bg: Dunk2,
    },
    {
      name: 'Positive Units',
      value: '0',
      bg: Dunk3,
    },
  ]);

  const { getGamesByDate, getAllInjuredPlayers, getSeasonStats } =
    usePosOddsAPI();

  const handleGetScheduledGames = async () => {
    setIsLoading(true);

    try {
      const date = moment().add(5, 'minutes').format('YYYY-MM-DD');
      const res = await getGamesByDate(date);

      if (res.data.data.length > 0) {
        const scheduledGames = res.data.data.filter(
          (game) => game.Status === 'Scheduled'
        );
        setSchedGames(scheduledGames);
        console.log('SCHEDULED', scheduledGames);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setSchedGames([]);
      setIsLoading(false);
    }
  };

  const handleGetLiveGames = async () => {
    setIsLoading(true);
    let gamesToday = [];
    const estDateToday = moment()
      .tz('America/Los_Angeles')
      .format('YYYY-MM-DD');

    try {
      const res = await getGamesByDate(estDateToday);

      if (res.data.data.length === 0) setLiveGames([]);
      setTodayGames([]);
      const onGoingGames = res.data.data.filter(
        (game) => game.Status === 'InProgress'
      );

      for (let i = 0; i < onGoingGames.length; i++) {
        gamesToday.push(onGoingGames[i]);
      }
      setTodayGames(res.data.data);
      setLiveGames(
        (gamesToday && gamesToday.length && gamesToday.slice(0, 2)) || []
      );
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setLiveGames([]);
      setTodayGames([]);
      setIsLoading(false);
    }
  };

  const handleGetAllInjuredPlayers = async () => {
    try {
      const res = await getAllInjuredPlayers();
      console.log(res);
    } catch (error) {}
  };

  const handleGetSeasonStats = async () => {
    try {
      const res = await getSeasonStats();
      const seasonStats = res.data[0];

      setSeasonStats([
        {
          name: 'Plays',
          value: seasonStats.plays,
          bg: Dunk1,
        },
        {
          name: 'Lines Watched',
          value: seasonStats.linesWatched,
          bg: Dunk2,
        },
        {
          name: 'Total Units Won',
          value: `+${seasonStats.playsWon}`,
          bg: Dunk3,
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetAllInjuredPlayers();
    handleGetScheduledGames();
    handleGetSeasonStats();
  }, []);

  useEffect(() => {
    handleGetLiveGames();

    const intervalId = setInterval(handleGetLiveGames, 15000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className="grid gap-8">
      <SearchBar
        pageTitle={'Dashboard'}
        searchValue={''}
        publicView={userRole !== 'admin'}
      />
      {/* {userRole === 'admin' && (
        <AdminDashboard
          cardData={seasonStats}
          liveGames={liveGames}
          signalData={signalData}
          injuredData={injuredData}
          statsData={statsData}
        />
      )}
      {userRole !== 'admin' && (
        <PublicDashboard
          cardData={seasonStats}
          todayGames={todayGames}
          schedGames={schedGames}
          injuredData={injuredData}
        />
      )} */}
      <PublicDashboard
        cardData={seasonStats}
        todayGames={todayGames}
        schedGames={schedGames}
        injuredData={injuredData}
      />
    </div>
  );
}
