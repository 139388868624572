import { useForm } from 'react-hook-form';
import InputField from '../../components/InputField/InputField';
import SearchBar from '../../components/SearchBar';
import SideBar from '../../components/SideBar';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import { useState } from 'react';
import BaseCard from '../../components/Card';
import SuccessModal from './successModal';
import LoaderModal from '../../components/Modal/LoaderModal';
import { usePosOddsAPI } from '../../hooks/usePosOddsAPI';

export function AdminConfig() {
  const userRole = localStorage.getItem('role');
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const { setSeasonStats } = usePosOddsAPI();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({ mode: 'onSubmit' });

  const onSubmit = async (data) => {
    setIsLoading(true);

    try {
      const res = await setSeasonStats(data);

      if (res.status === 200) {
        setIsLoading(false);
        reset();
        setShowSuccessModal(true);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full h-screen bg-main-bg-gray text-main-white font-inter flex">
      <SideBar />
      <div className="pl-16 pr-6 w-4/5">
        <SearchBar
          pageTitle={'Admin Config'}
          searchValue={''}
          publicView={userRole !== 'admin'}
        />
        <div className="flex flex-col w-full justify-center items-center">
          <div className="w-4/5 mt-16 grid gap-2">
            <div className="font-normal text-lg w-full">Set configuration</div>
            <BaseCard className="px-8 grid gap-12">
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="grid gap-3 w-full"
              >
                <div className="grid gap-1">
                  <label htmlFor="plays">Plays</label>
                  <InputField
                    registerField={register}
                    required={true}
                    type="text"
                    fieldId="plays"
                    placeholder="Plays"
                    className="bg-secondary-bg-gray"
                  ></InputField>
                  {errors?.plays?.type === 'required' && (
                    <ErrorMessage message={'Must not be empty.'} />
                  )}
                </div>
                <div className="grid gap-1">
                  <label htmlFor="linesWatched">Lines watched</label>
                  <InputField
                    registerField={register}
                    required={true}
                    type="text"
                    fieldId="linesWatched"
                    placeholder="Lines watched"
                    className="bg-secondary-bg-gray"
                  ></InputField>
                  {errors?.linesWatched?.type === 'required' && (
                    <ErrorMessage message={'Must not be empty.'} />
                  )}
                </div>
                <div className="grid gap-1">
                  <label htmlFor="playsWon">Plays won</label>
                  <InputField
                    registerField={register}
                    required={true}
                    type="text"
                    fieldId="playsWon"
                    placeholder="Plays won"
                    className="bg-secondary-bg-gray"
                  ></InputField>
                  {errors?.playsWon?.type === 'required' && (
                    <ErrorMessage message={'Must not be empty.'} />
                  )}
                </div>
                <div className="w-full flex justify-end mt-3">
                  <button
                    type="submit"
                    className={`text-main-dark-green bg-main-green px-4 py-3 rounded-md flex w-20 justify-center`}
                    disabled={isLoading}
                  >
                    Set
                  </button>
                </div>
              </form>
            </BaseCard>
          </div>
        </div>
      </div>
      <LoaderModal isVisible={isLoading} />
      <SuccessModal
        isVisible={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
        onSuccess={() => setShowSuccessModal(false)}
      />
    </div>
  );
}
