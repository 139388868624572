import React, { useState, useEffect } from 'react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  getPaginationRowModel,
} from '@tanstack/react-table';
import SideBar from '../../components/SideBar';
import { usePosOddsAPI } from '../../hooks/usePosOddsAPI';
import { parseTeamName } from '../../utils';
import moment from 'moment-timezone';
import { CSVLink } from 'react-csv';
import { TailSpin } from 'react-loader-spinner';
import OldPagination from '../../components/Pagination';
import * as NBAIcons from 'react-nba-logos';
import UpdateTeamConfigModal from '../../components/Modal/UpdateTeamConfigModal';
import SuccessTeamConfigModal from '../../components/Modal/SuccessTeamConfigModal';

const TeamManager = () => {
  const [data, setData] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [configModalVisible, setConfigModalVisible] = useState(false);
  const [selectedTeamConfig, setSelectedTeamConfig] = useState('');
  const [showSuccessTeamConfigModal, setShowSuccessTeamConfigModal] =
    useState(false);
  const [pastPageIndex, setPastPageIndex] = useState(0);

  const { getAllTeamConfig } = usePosOddsAPI();

  const handleGetTeams = async () => {
    setIsLoading(true);
    try {
      const res = await getAllTeamConfig();
      const teamConfigData = res.data;
      const data = [];

      for (let teamCode in teamConfigData) {
        const Icon = await NBAIcons[parseTeamName(teamCode)];
        data.push({
          ...teamConfigData[teamCode],
          teamAbbr: teamCode,
          teamIcon: <Icon size={'64px'} />,
        });
      }
      setData(data);
      table.setPageIndex(pastPageIndex);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleGetTeams();
  }, []);

  const columns = [
    columnHelper.accessor('name', {
      id: 'name',
      header: () => <p className="text-xs font-normal text-white pl-4">Team</p>,
      cell: (info) => (
        <div className="flex items-center gap-1 pl-1">
          {info.row.original.teamIcon}
          <p className="text-sm font-normal text-navy-700 dark:text-white">
            {info.row.original.team}
          </p>
          <p className="text-sm font-normal text-navy-700 dark:text-white ml-3">
            {info.getValue()}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor('requiredMovementForTrendingSignal', {
      id: 'requiredMovementForTrendingSignal',
      header: () => (
        <p className="text-xs font-normal text-white text-center">
          Trending alert indicator
        </p>
      ),
      cell: (info) => (
        <p className="text-xs font-normal text-white text-center">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor('requiredMovementForMoneyLineSignal', {
      id: 'requiredMovementForMoneyLineSignal',
      header: () => (
        <p className="text-xs font-normal text-white text-center">
          Signal alert indicator
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-normal text-navy-700 dark:text-white text-center">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor('enableHomeMoneylineAlertSMS', {
      id: 'enableHomeMoneylineAlertSMS',
      header: () => <p className="text-xs font-normal text-white"></p>,
      cell: (info) => (
        <div className="flex items-center justify-center gap-4">
          <div>
            {/* <div className="flex items-center gap-2 justify-between">
              <p className="text-xs font-normal text-navy-700 dark:text-white">
                In the money SMS alerts
              </p>
              <div
                className={`text-[10px] rounded-3xl font-normal dark:text-white px-2 py-1 ${
                  info.getValue() ? 'bg-main-green' : 'bg-main-red'
                }`}
              >
                {info.getValue() ? 'ON' : 'OFF'}
              </div>
            </div> */}
            {/* <div className="flex items-center gap-2 mt-2 justify-between">
              <p className="text-xs font-normal text-navy-700 dark:text-white">
                Trending SMS alerts
              </p>
              <div
                className={`text-[10px] rounded-3xl font-normal dark:text-white px-2 py-1 ${
                  info.row.original.enableHomeTrendAlertSMS
                    ? 'bg-main-green'
                    : 'bg-main-red'
                }`}
              >
                {info.row.original.enableHomeTrendAlertSMS ? 'ON' : 'OFF'}
              </div>
            </div> */}
          </div>
          <button
            className="text-main-dark-green font-normal text-xs rounded-md p-2 bg-main-green"
            onClick={() => {
              console.log(table.getState().pagination.pageIndex);
              setPastPageIndex(table.getState().pagination.pageIndex);
              setSelectedTeamConfig(info.row.original);
              setConfigModalVisible(true);
            }}
          >
            Update Team Config
          </button>
        </div>
      ),
    }),
  ];
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },

    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });

  return (
    <div className="font-bold w-full h-screen bg-main-bg-gray text-main-white font-inter flex">
      <SideBar />

      <div className="pl-16 pr-6 w-4/5">
        <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
          <div className="text-2xl ml-4">Team Manager</div>
          <div className="flex justify-end">
            <CSVLink
              data={data}
              filename={`team-managers${moment().format('MMM_D_YYYY')}.csv`}
              className="flex"
            >
              <button
                type="submit"
                className={`text-main-dark-green font-medium bg-main-green w-fit px-4 py-3 rounded-md mx-4`}
              >
                Export
              </button>
            </CSVLink>
          </div>
          <div className="w-full overflow-x-auto mr-4 mb-12 mt-5">
            <table
              className="w-full mt-2 shadow-lg border-collapse border-0"
              style={{ border: '1px solid #2A3635' }}
              hidden={isLoading}
            >
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr
                    key={headerGroup.id}
                    className="!border-px !border-gray-400"
                  >
                    {headerGroup.headers.map((header) => {
                      return (
                        <th
                          key={header.id}
                          colSpan={header.colSpan}
                          onClick={header.column.getToggleSortingHandler()}
                          className="cursor-pointer border-b-[1px] border-gray-200 pl-4 pt-4 pb-4 pr-4 text-start"
                        >
                          <div className="items-center justify-between text-xs text-gray-200">
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: '',
                              desc: '',
                            }[header.column.getIsSorted()] ?? null}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table
                  .getRowModel()
                  .rows.slice(0, 10)
                  .map((row) => {
                    return (
                      <tr
                        className={`${
                          row.id % 2 === 0
                            ? 'bg-tb-row-gray-1'
                            : 'bg-tb-row-gray-2'
                        }`}
                        key={row.id}
                      >
                        {row.getVisibleCells().map((cell) => {
                          return (
                            <td
                              height={'74px'}
                              key={cell.id}
                              className="min-w-[150px] border-white/0 py-2 pr-4 pl-4 align-middle"
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {data && data.length > 0 && <OldPagination table={table} />}
          </div>
          <div
            hidden={!isLoading}
            className="flex h-full w-11/12 justify-center items-center"
          >
            <TailSpin
              height="120"
              width="120"
              color="#5AC561"
              ariaLabel="tail-spin-loading"
              radius="0"
              wrapperStyle={{}}
              wrapperClass=""
              visible={isLoading}
            />
          </div>
        </div>
      </div>
      <UpdateTeamConfigModal
        isVisible={configModalVisible}
        onClose={() => {
          table.setPageIndex(pastPageIndex);
          setSelectedTeamConfig('');
          setConfigModalVisible(false);
        }}
        selectedTeamConfig={selectedTeamConfig}
        setShowSuccessTeamConfigModal={setShowSuccessTeamConfigModal}
        onSuccess={() => {
          handleGetTeams();
        }}
      />

      <SuccessTeamConfigModal
        isVisible={showSuccessTeamConfigModal}
        onClose={() => {
          console.log('pastPageIndex', pastPageIndex);
          table.setPageIndex(pastPageIndex);
          setShowSuccessTeamConfigModal(false);
        }}
        selectedTeam={selectedTeamConfig.name}
        setShowSuccessTeamConfigModal={setShowSuccessTeamConfigModal}
        onSuccess={() => {
          table.setPageIndex(pastPageIndex);
        }}
      />
    </div>
  );
};

export default TeamManager;
const columnHelper = createColumnHelper();
